import React, {useEffect, useRef, useState} from 'react';
import landscape_vd from "./img/HomePage/1 - Header Video/Vieunite Launch - Homepage - Landscape 2.mp4";
import "./LandingPage.css"
import landing from "./img/landing_welcome.webp"
import cover from "./img/landing_frame.webp"
import landing_video from "./img/landing_main.mp4"
import background1 from "./img/landing_background1.webp"
import background2 from "./img/landing_background222.webp"
import {AnimatePresence, motion} from "framer-motion";

import action11 from "./img/Actions/1-1.png"
import action12 from "./img/Actions/1-2.png"
import action13 from "./img/Actions/1-3.png"
import action14 from "./img/Actions/1-4.png"
import action15 from "./img/Actions/1-5.png"
import action16 from "./img/Actions/1-6.png"
import action17 from "./img/Actions/1-7.png"
import action21 from "./img/Actions/2-1.png"
import action22 from "./img/Actions/2-2.png"
import action23 from "./img/Actions/2-3.png"
import action24 from "./img/Actions/2-4.png"
import action25 from "./img/Actions/2-5.png"
import action26 from "./img/Actions/2-6.png"
import action27 from "./img/Actions/2-7.png"

import demo1 from "./img/CreationShowcases/demo1.png"
import demo2 from "./img/CreationShowcases/demo2.png"
import demo3 from "./img/CreationShowcases/demo3.png"
import demo4 from "./img/CreationShowcases/demo4.png"
import demo5 from "./img/CreationShowcases/demo5.png"
import demo6 from "./img/CreationShowcases/demo6.png"
import demo7 from "./img/CreationShowcases/demo7.png"
import demo8 from "./img/CreationShowcases/demo8.png"
import demo9 from "./img/CreationShowcases/demo9.png"
import demo10 from "./img/CreationShowcases/demo10.png"
import demo11 from "./img/CreationShowcases/demo11.png"
import demo12 from "./img/CreationShowcases/demo12.png"
import demo13 from "./img/CreationShowcases/demo13.png"
import demo14 from "./img/CreationShowcases/demo14.png"
import demo15 from "./img/CreationShowcases/demo15.png"
import demo16 from "./img/CreationShowcases/demo16.png"
import demo17 from "./img/CreationShowcases/demo17.png"
import demo18 from "./img/CreationShowcases/demo18.png"

import innovating_cover from "./img/landing_innovating_cover.png"
import UKTILogo from "./img/HomePage/9 - Logos/innovate - 2024.svg";
import RoyalLogo from "./img/HomePage/9 - Logos/the royal society.svg";
import NTULogo from "./img/HomePage/9 - Logos/ntu.svg";
import pavilionLogo from "./img/HomePage/9 - Logos/the_royal_pavilion.png";
import oxfordLogo from "./img/HomePage/9 - Logos/University_of_Oxford-Logo.png"
import {Carousel} from "antd";

const Landing = (props) => {
    const demoArts = [
        {img: demo1, artwork: "'Under the Wave off Kanagawa'", artist: "Katsushika Hokusai"},
        {img: demo2, artwork: "Lady Agnew of Lochnaw", artist: "John singer Sargent"},
        {img: demo3, artwork: "'Winter Night in the Mountains'", artist: "Harald Sohlberg"},
        {img: demo4, artwork: "Final Study for' Bathers at Asnieres'", artist: "Georges Seurat"},
        {img: demo5, artwork: "'Equestrienne (At the Cirque Fernando)'", artist: "Henri de Toulouse-Lautrec"},
        {img: demo6, artwork: "Mrs.WilliamBonham", artist: "William Bonnell"},
        {img: demo7, artwork: "Neuquen", artist: "Pehuen"},
        {img: demo8, artwork: "Girl and Bike", artist: "Anthony Webb"},
        {img: demo9, artwork: 'Sleeve Shock', artist: "Jonathan Armour"},
        {img: demo10, artwork: 'Reverend Robert Walker 1755-1808', artist: "Sir Henry Raeburn"},
        {img: demo11, artwork: "Gratitude", artist: "Caitlin Flood-Molyneux"},
        {img: demo12, artwork: "Claude Monet-Stacks of Wheat (EndofDay,Autumn)", artist: "Oscar"},
        {img: demo13, artwork: "Dr. Francois Brabander", artist: "Amedeo Modigliani"},
        {img: demo14, artwork: "'Still Dancing 4255'", artist: "Richard Battye"},
        {img: demo15, artwork: "The Virgin Adoring the Sleeping Christ", artist: "Sandro Botticelli"},
        {img: demo16, artwork: "GARDEN’S Heart", artist: "Fazar Roma Agung Wibisono"},
        {img: demo17, artwork: "Dawn or Dusk", artist: "Barbara Safran de Niverville"},
        {img: demo18, artwork: "'TheSpring of the Forest'", artist: "Zehao Sun"}
    ]

    const [scale, setScale] = useState(1);
    const [xDrift, setXDrift] = useState(0);
    const [yDrift, setYDrift] = useState(0);
    const [rotate, setRotate] = useState(0);
    const [showTitle, setShowTitle] = useState(true);
    const [brightness, setBrightness] = useState(1);
    const [brightness1, setBrightness1] = useState(1);
    const [brightness2, setBrightness2] = useState(1);
    const homeSectionRef = useRef(null);
    const [homeXOffset, setHomeXOffset] = useState(0);

    const handleScroll = () => {
        let scroll = {
            x: document.documentElement.scrollLeft,
            y: document.documentElement.scrollTop
        };
        const vh = window.innerHeight;
        const vw = window.innerHeight;

        const scrollLength = Math.max(scroll.y / vh, 0)

        // console.log(scrollLength)

        setBrightness1(scrollLength > 1.7 ? 0.7 : 1)
        setBrightness2(scrollLength > 2.7 ? 0.7 : 1)

        const percentage = Math.min(scrollLength, 1)

        setRotate(-30 * percentage);
        setXDrift((100 / 5) * percentage);
        setYDrift((-100 / 8) * percentage);
        setScale(4 * percentage + 1);
        setShowTitle(percentage < 0.12);
        setBrightness(percentage > 0.4 ? 0.7 : 1);

        if (homeSectionRef.current) {
            const homeOffset = homeSectionRef.current.getBoundingClientRect().top
            const offset = Math.min(vh - homeOffset, vh);
            if (offset < 0) {
                // console.log("home offset: " + 0)
                setHomeXOffset(0)
            } else {
                // console.log("home offset: " + (offset / vh))
                setHomeXOffset(offset / vh * 100)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const action1_list = [action11, action12, action13, action14, action15, action16, action17, action11, action12, action13, action14, action15, action16, action17]
    const action1_titles = [
        'Antarctica Art Exhibition',
        "Celebrating Anthony Webb",
        "\"Birth Sleeves\" Exhibition",
        "A Year of Growth",
        "Vieunite partners with Estorick Collection",
        "Vieunite’s Approach to Digital Canvas",
        "Vieunite Partners with RBSA"
    ]
    const action1_bodies = [
        "Vieunite Artist, Guillermo Giagante, held an exhibition in Argentina that featured stunning photos from his visit to Antarctica on the Textura digital canvas, creating an immersive experience with vivid textures.",
        "Ant, a talented photographer, left a lasting impact on the art world. Vieunite is now showcasing his work on the Textura digital canvas, preserving his legacy and promoting mental health awareness.",
        "Jonathan Armour, one of our Vieunite Artists, recently held an exhibition titled “Birth Sleeves”. His work was displayed on Textura, highlighting the intricate details of his art.",
        "In our first year, we enhanced our platform, formed key partnerships, and showcased our Textura digital canvas. Exciting collaborations await in 2024 as we continue to make art more accessible.",
        "Vieunite has partnered with the Estorick Collection of Modern Italian, a renowned museum that features unique pieces from Italian Futurists.",
        "The Textura digital canvas prioritises artistry and unmatched display quality, transforming spaces with captivating artwork.",
        "Vieunite has partnered with the RBSA Gallery, bringing both contemporary and historical artwork to the Vieunite app. This provides a dedicated area for artists to showcase their exceptional work."
    ]
    const action1_buttons = [
        "Learn More",
        "Explore Ant’s Journey",
        "Visit the Exhibition",
        "Explore Our First Year",
        "Learn More",
        "Explore More",
        "Learn More"
    ]
    const action1_links = [
        "https://vieunite.com/news/vieunite-proudly-supports-antarctica-a-challenge-to-the-senses-art-exhibition-in-argentina/",
        "https://vieunite.com/news/celebrating-anthony-webb-a-photographer-with-an-artists-eye/",
        "https://vieunite.com/jonathanarmour/",
        "https://vieunite.com/news/vieunite-2023-in-review-a-year-of-milestones-and-growth/",
        "https://vieunite.com/news/fusing-art-and-technology-vieunites-partnership-with-estorick-collection/",
        "https://vieunite.com/news/beyond-the-frame-texturas-innovative-approach-to-the-digital-canvas/",
        "https://vieunite.com/news/uniting-art-and-innovation-vieunite-joins-forces-with-rbsa-gallery-for-milestone-partnership/"
    ]
    const action1_title_list = [...action1_titles, ...action1_titles]
    const action1_body_list = [...action1_bodies, ...action1_bodies]
    const action1_link_list = [...action1_links, ...action1_links]
    const action1_button_list = [...action1_buttons, ...action1_buttons]

    const action2_list = [action21, action22, action23, action24, action25, action26, action27, action21, action22, action23, action24, action25, action26, action27]
    const action2_titles = [
        "Ideal Home Show Exhibition",
        "What Makes Textura Unique",
        "The Journey of Anthony Webb",
        "Vieunite Now at Smartech",
        "Grow Your Audience",
        "Ruthin International Arts Festiva",
        "Vieunite Pro - All You Need to Know"
    ]

    const action2_bodies = [
        "Vieunite recently showcased the Textura digital canvas at the Ideal Home Show in London, featured in The Dream Home and The Smart Home exhibits, highlighting innovation in interior design.",
        "The Textura digital canvas uses texture-accurate display technology that replicates the intricate details of artwork. Plus, it seamlessly integrates into any décor, transforming spaces into personal galleries.",
        "Anthony Webb was a passionate and visionary photographer who collaborated with esteemed clients. Vieunite is now proudly showcasing his remarkable work in honour of his legacy.",
        "The Textura digital canvas is now available at Smartech in Selfridges, London. This milestone reflects our commitment to innovation and making art more accessible.",
        "Join our journey as a Vieunite Artist and connect with a thriving community of art enthusiasts, expanding your reach and showcasing your work to a diverse audience passionate about creativity.",
        "We recently showcased the Textura digital canvas at the Ruthin International Arts Festival where over 40 artists celebrated cultural diversity and human connection.",
        "Vieunite Pro is revolutionizing business communication by merging art and technology through the Textura digital canvas, helping businesses engage and connect with their audiences."
    ]
    const action2_links = [
        "https://vieunite.com/news/experience-the-future-of-living-at-the-ideal-home-show-with-vieunites-textura-digital-canvas/",
        "https://vieunite.com/news/what-makes-the-textura-digital-canvas-unique/",
        "https://vieunite.com/anthonywebb/",
        "https://vieunite.com/news/vieunites-textura-digital-canvas-hits-smartech-in-selfridges-london/",
        "https://vieunite.com/Become_a_Vieunite_Artist_Master.pdf",
        "https://www.instagram.com/p/C9NEr9FtIIo/?img_index=1",
        "https://vieunite.com/news/redefining-business-communication-how-vieunites-revolutionary-digital-medium-is-shaping-the-future/"
    ]
    const action2_buttons = [
        "See More",
        "Explore Textura",
        "Visit Ant’s Artist Page",
        "Find Us at Smartech",
        "Become a Vieunite Artist",
        "Explore the Exhibition",
        "Learn More"
    ]
    const action2_title_list = [...action2_titles, ...action2_titles]
    const action2_body_list = [...action2_bodies, ...action2_bodies]
    const action2_link_list = [...action2_links, ...action2_links]
    const action2_button_list = [...action2_buttons, ...action2_buttons]

    const [action1Pause, setAction1Pause] = useState(false);
    const [action2Pause, setAction2Pause] = useState(false);

    return (<div style={{minWidth: '900px'}}>
        <div className={'landingVideoContainer'}>
            <video
                playsInline
                loop
                autoPlay
                muted
                rel={"preload"}
                className={'landingVideoImg'}
                style={{transition: 'filter 2s ease', filter: `brightness(${brightness})`}}
            >
                <source src={landing_video} type={'video/mp4'}></source>
            </video>

            {/*<img className={'landingVideoImg'}*/}
            {/*     style={{transition: 'filter 2s ease', filter: `brightness(${brightness})`}} src={landing}/>*/}
            <img className={'landingVideoImg'} style={{
                transform: `translateX(${xDrift}vw) translateY(${yDrift}vh) scale(${scale}) rotate(${rotate}deg)`,
                transition: 'transform 0.2s ease'
            }} src={cover}/>

            <AnimatePresence mode="wait">
                {showTitle && (
                    <motion.div
                        key={showTitle}
                        initial={{x: -40, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        exit={{x: 40, opacity: 0}}
                        transition={{duration: 0.5}}
                    >
                        <div style={{
                            backgroundColor: 'transparent',
                            color: 'white',
                            position: 'absolute',
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <div className={'spacer'} style={{minWidth: '60vw'}}></div>
                            <div>
                                <p className={'landingTitle'}>
                                    Inspiring Connection,<br/>Uniting People.<br/>
                                </p>
                                <Carousel
                                    dots={true}
                                    dotPosition={"left"}
                                    autoplay={true}
                                    autoplaySpeed={2000}
                                    // afterChange={(current) => {console.log("after change title" + current)}}
                                >
                                    <div>
                                        <p className={'landingSubtitle'}>At Home</p>
                                    </div>
                                    {/*<div style={{height: '10px'}}></div>*/}
                                    <div>
                                        <p className={'landingSubtitle'}>For Business</p>
                                    </div>
                                </Carousel>
                            </div>

                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>

        <div style={{
            position: "relative",
            width: '100%',
            height: '100vh',
            display: 'flex',
            color: 'white',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            justifyContent: 'center'
        }}>
            <p className={'landingDescription'}>At Vieunite, we make art accessible through our innovative Textura
                digital canvas, offering a diverse collection that enriches any space. Our mission is to revolutionise
                how people and businesses experience
                art, making it an integral part of daily life.</p>
        </div>

        <div style={{height: '20vw'}}></div>

        <div style={{
            height: '100vh',
            width: '100vw',
            position: 'sticky',
            backgroundColor: 'white',
            top: '0',
            overflow: "hidden",
        }}>


            <div style={{
                width: '200vw',
                height: '100vh',
                // position: 'sticky',
                backgroundColor: 'white',
                position: 'relative',
                // top: '0',
                display: 'flex',
                overflow: "hidden",
                transform: `translateX(${-homeXOffset}vw)`,
                transition: 'transform 0.2s ease'
            }}>
                {/*<div style={{width: '200vw', height: '100vh', backgroundColor: 'red'}}></div>*/}
                <div className={'landingBlock'}
                     style={{
                         display: 'flex',
                         flexDirection: 'row-reverse',
                     }}>
                    <img className={'landingVideoImg'}
                         style={{transition: 'filter 2s ease', filter: `brightness(${brightness1})`}}
                         src={background1}/>

                    <AnimatePresence mode="wait">
                        {brightness1 === 0.7 && (
                            <motion.div
                                key={brightness1}
                                initial={{x: -40, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                exit={{x: 40, opacity: 0}}
                                transition={{duration: 0.5}}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    position: 'relative',
                                    display: "flex",
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '9vw'
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="209" height="67" viewBox="0 0 209 67"
                                     fill="none">
                                    <g clip-path="url(#clip0_78_240)">
                                        <path
                                            d="M13.4653 35.3105L0 0.939453H8.48771L18.086 27.3441L27.7041 0.939453H36.1918L22.7264 35.3105H13.4653Z"
                                            fill="white"/>
                                        <path
                                            d="M38.5723 3.79725C38.5723 1.6246 40.3571 0 42.5385 0C44.7199 0 46.5047 1.70289 46.5047 3.79725C46.5047 5.89162 44.7199 7.71195 42.5385 7.71195C40.3571 7.71195 38.5723 6.00906 38.5723 3.79725ZM39.2267 10.4131H45.8701V35.2518H39.2267V10.4131Z"
                                            fill="white"/>
                                        <path
                                            d="M50.7871 22.8426C50.7871 15.6396 56.1812 9.78711 63.836 9.78711C71.4908 9.78711 76.4684 15.2873 76.4684 23.4885V24.9566H57.728C58.2039 28.1275 60.7027 30.6916 64.9663 30.6916C69.23 30.6916 70.0629 29.7521 71.6693 28.1666L74.5844 32.3749C72.0262 34.665 68.06 35.8394 64.1929 35.8394C56.6571 35.8981 50.7871 30.809 50.7871 22.8426ZM63.8955 14.9937C59.8103 14.9937 58.0255 18.0471 57.728 20.4938H70.1224C69.944 18.0863 68.2187 14.9937 63.8955 14.9937Z"
                                            fill="white"/>
                                        <path
                                            d="M98.2439 35.3096V32.1387C96.5186 34.0765 93.4448 35.8772 89.3397 35.8772C85.2347 35.8772 81.209 32.8238 81.209 28.087V10.3926H87.8524V25.5033C87.8524 28.9678 89.6372 30.0639 92.473 30.0639C95.3089 30.0639 97.0342 28.6546 98.2241 27.2453V10.4904H104.867V35.3292L98.2241 35.2705L98.2439 35.3096Z"
                                            fill="white"/>
                                        <path
                                            d="M128.505 35.3108V20.2587C128.505 16.7942 126.721 15.6394 123.885 15.6394C121.049 15.6394 119.264 17.1074 118.134 18.5754V35.3303H111.49V10.4329H118.134V13.6625C119.799 11.7247 122.814 9.86523 126.978 9.86523C131.143 9.86523 135.169 12.9774 135.169 17.8316V35.4086L128.525 35.3499L128.505 35.3108Z"
                                            fill="white"/>
                                        <path
                                            d="M140.959 3.79725C140.959 1.6246 142.744 0 144.925 0C147.107 0 148.891 1.70289 148.891 3.79725C148.891 5.89162 147.107 7.71195 144.925 7.71195C142.744 7.71195 140.959 6.00906 140.959 3.79725ZM141.613 10.4131H148.257V35.2518H141.613V10.4131Z"
                                            fill="white"/>
                                        <path
                                            d="M156.09 29.1056V16.1088H151.945V10.4325H156.09V3.69922H162.733V10.4912H167.83V16.1675H162.733V27.344C162.733 28.8707 163.566 30.1038 165.054 30.1038C166.541 30.1038 167.017 29.7515 167.374 29.3405L168.802 34.2534C167.85 35.1342 166.006 35.8976 163.289 35.8976C158.668 35.8976 156.11 33.49 156.11 29.1056"
                                            fill="white"/>
                                        <path
                                            d="M170.805 22.8426C170.805 15.6396 176.199 9.78711 183.854 9.78711C191.508 9.78711 196.486 15.2873 196.486 23.4885V24.9566H177.746C178.222 28.1275 180.72 30.6916 184.984 30.6916C189.248 30.6916 190.081 29.7521 191.687 28.1666L194.602 32.3749C192.044 34.665 188.078 35.8394 184.211 35.8394C176.615 35.8981 170.805 30.809 170.805 22.8426ZM183.913 14.9937C179.828 14.9937 178.043 18.0471 177.746 20.4938H190.14C189.962 18.0863 188.236 14.9937 183.913 14.9937Z"
                                            fill="white"/>
                                        <path
                                            d="M200.473 31.6692C200.473 29.3791 202.436 27.4609 204.736 27.4609C207.037 27.4609 209 29.3987 209 31.6692C209 33.9398 207.037 35.8775 204.736 35.8775C202.436 35.8775 200.473 33.9398 200.473 31.6692Z"
                                            fill="white"/>
                                        <path
                                            d="M116.388 66.5295L119.085 54.4331H102.744L100.047 66.5295H97.8457L103.517 41.084H105.719L103.2 52.4562H119.501L122.059 41.084H124.3L118.629 66.5295H116.388Z"
                                            fill="white"/>
                                        <path
                                            d="M135.901 47.6406C140.919 47.6406 144.131 50.8507 144.131 55.7245C144.131 60.5983 139.768 66.9792 133.859 66.9792C127.949 66.9792 125.629 63.7692 125.629 58.8954C125.629 54.0216 129.992 47.6406 135.901 47.6406ZM135.862 49.4414C131.182 49.4414 127.83 54.0607 127.83 58.8171C127.83 63.5734 130.071 65.1785 133.898 65.1785C137.726 65.1785 141.93 60.5591 141.93 55.8028C141.93 51.0464 139.689 49.4414 135.862 49.4414Z"
                                            fill="white"/>
                                        <path
                                            d="M169.219 66.5291L172.074 53.7084C172.114 53.5519 172.233 53.0625 172.233 52.5928C172.233 50.5767 170.646 49.4219 168.842 49.4219C167.037 49.4219 164.677 50.9486 163.23 52.6319L160.136 66.5095H158.093L160.989 53.6889C161.068 53.1212 161.147 52.9255 161.147 52.3579C161.108 50.7137 159.918 49.4219 157.677 49.4219C155.436 49.4219 153.612 50.9486 152.104 52.6319L149.011 66.5095H147.008L151.133 48.0909H153.136L152.521 50.8703C153.572 49.6959 155.773 47.6211 158.47 47.6211C161.167 47.6211 163.15 49.8329 163.15 51.1639V51.2813C164.618 49.4414 166.938 47.6211 169.595 47.6211C172.253 47.6211 174.315 49.187 174.315 52.123C174.315 55.059 174.196 53.1604 174.077 53.6889L171.222 66.5095H169.219V66.5291Z"
                                            fill="white"/>
                                        <path
                                            d="M188.573 47.6406C193.55 47.6406 196.485 51.1051 196.485 55.607C196.485 60.1089 196.287 57.4665 196.168 57.9363H180.402C180.363 58.1712 180.323 58.6605 180.323 59.0128C180.323 62.399 182.604 65.2763 187.165 65.2763C191.726 65.2763 191.191 64.6304 192.797 63.2994L193.57 64.7478C191.904 66.0005 189.505 66.9988 187.086 66.9988C181.473 66.9988 178.201 63.6713 178.201 58.8758C178.201 54.0803 182.762 47.6602 188.553 47.6602L188.573 47.6406ZM194.522 56.3704C194.562 56.1747 194.562 55.7636 194.562 55.5287C194.562 51.9468 192.361 49.3435 188.335 49.3435C184.309 49.3435 181.255 52.5927 180.601 56.3508H194.502L194.522 56.3704Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_78_240">
                                            <rect width="209" height="67" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div style={{height: '2rem'}}></div>
                                <p className={'landingBlockDescription'}>Bring the beauty of art and the warmth of
                                    personal
                                    memories into your home with a texture-accurate digital
                                    canvas that seamlessly displays both masterpieces and your own photos and
                                    videos.</p>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    style={{width: "100%"}}
                                    onClick={_ => window.location.href = '/home'}>
                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                        EXPLORE VIEUNITE HOME
                                    </p>
                                </button>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                <div className={'landingBlock'}
                     style={{
                         display: 'flex',
                         flexDirection: 'row',
                     }}>
                    <img className={'landingVideoImg'}
                         style={{transition: 'filter 2s ease', filter: `brightness(${brightness2})`}}
                         src={background2}/>
                    <div
                        style={{
                            width: '50%',
                            height: '100%',
                            position: 'relative',
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end',
                            padding: '9vw'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="209" height="68" viewBox="0 0 209 68"
                             fill="none">
                            <g clip-path="url(#clip0_78_256)">
                                <path
                                    d="M13.4531 35.7765L0 0.953125H8.4727L18.0963 27.7007L27.7 0.953125H36.1925L22.7195 35.7765H13.4531Z"
                                    fill="white"/>
                                <path
                                    d="M38.5547 3.84943C38.5547 1.64692 40.3405 0 42.5232 0C44.7058 0 46.4916 1.72629 46.4916 3.84943C46.4916 5.97256 44.7058 7.81791 42.5232 7.81791C40.3405 7.81791 38.5547 6.09162 38.5547 3.84943ZM39.2095 10.5562H45.8567V35.7163H39.2095V10.5562Z"
                                    fill="white"/>
                                <path
                                    d="M50.7773 23.1353C50.7773 15.8333 56.1745 9.90039 63.8336 9.90039C71.4928 9.90039 76.4733 15.4761 76.4733 23.7901V25.2783H57.7222C58.1984 28.4729 60.6787 31.0921 64.9647 31.0921C69.2506 31.0921 70.0642 30.1396 71.6714 28.5324L74.5882 32.7985C72.0286 35.1201 68.0601 36.2908 64.2107 36.2908C56.6705 36.3503 50.7972 31.1913 50.7972 23.1154M63.8932 15.1785C59.8056 15.1785 58.0198 18.254 57.7222 20.7542H70.1237C69.9451 18.3136 68.2188 15.1785 63.8932 15.1785Z"
                                    fill="white"/>
                                <path
                                    d="M98.2396 35.7763V32.5817C96.5133 34.5461 93.4378 36.3716 89.3502 36.3716C85.2627 36.3716 81.2148 33.296 81.2148 28.4743V10.5566H87.862V25.8551C87.862 29.3474 89.6479 30.4784 92.4853 30.4784C95.3228 30.4784 97.0491 29.0498 98.2396 27.6211V10.6559H104.887V35.816L98.2396 35.7565V35.7763Z"
                                    fill="white"/>
                                <path
                                    d="M128.497 35.7759V20.5369C128.497 17.0447 126.712 15.8541 123.874 15.8541C121.037 15.8541 119.251 17.3423 118.12 18.8106V35.7759H111.473V10.5562H118.12V13.8104C119.787 11.846 122.803 9.96094 126.95 9.96094C131.097 9.96094 135.145 13.096 135.145 18.0368V35.8354L128.497 35.7759Z"
                                    fill="white"/>
                                <path
                                    d="M140.961 3.84943C140.961 1.64692 142.747 0 144.929 0C147.112 0 148.898 1.72629 148.898 3.84943C148.898 5.97256 147.112 7.81791 144.929 7.81791C142.747 7.81791 140.961 6.09162 140.961 3.84943ZM141.616 10.5562H148.263V35.7163H141.616V10.5562Z"
                                    fill="white"/>
                                <path
                                    d="M156.081 29.4859V16.3105H151.934V10.5563H156.081V3.73047H162.728V10.6158H167.827V16.3701H162.728V27.7001C162.728 29.2478 163.561 30.4979 165.049 30.4979C166.538 30.4979 167.014 30.1407 167.371 29.724L168.8 34.7045C167.847 35.5974 166.002 36.3712 163.283 36.3712C158.66 36.3712 156.1 33.9306 156.1 29.4859"
                                    fill="white"/>
                                <path
                                    d="M170.805 23.1353C170.805 15.8333 176.202 9.90039 183.861 9.90039C191.52 9.90039 196.501 15.4761 196.501 23.7901V25.2783H177.75C178.226 28.4729 180.706 31.0921 184.992 31.0921C189.278 31.0921 190.091 30.1396 191.699 28.5324L194.616 32.7985C192.056 35.1201 188.087 36.2908 184.238 36.2908C176.638 36.3503 170.825 31.1913 170.825 23.1154M183.921 15.1785C179.833 15.1785 178.047 18.254 177.75 20.7542H190.151C189.972 18.3136 188.246 15.1785 183.921 15.1785Z"
                                    fill="white"/>
                                <path
                                    d="M200.467 32.106C200.467 29.7844 202.431 27.8398 204.733 27.8398C207.035 27.8398 208.999 29.8042 208.999 32.106C208.999 34.4077 207.035 36.3721 204.733 36.3721C202.431 36.3721 200.467 34.4077 200.467 32.106Z"
                                    fill="white"/>
                                <path
                                    d="M123.676 67.4061L129.351 41.6309H138.399C142.447 41.6309 145.165 44.6469 145.165 48.0796C145.165 51.5124 142.467 56.6912 135.621 56.6912H128.279L125.878 67.3863H123.676V67.4061ZM135.879 54.6872C140.482 54.6872 142.804 51.6314 142.804 48.2384C142.804 44.8453 140.8 43.6349 138.042 43.6349H131.117L128.676 54.6872H135.879Z"
                                    fill="white"/>
                                <path
                                    d="M161.001 67.4061L156.14 56.7508H150.306L147.945 67.4061H145.742L151.417 41.6309H160.267C163.739 41.6309 167.251 43.9921 167.251 48.1193C167.251 52.2465 163.779 56.473 158.441 56.6516L163.501 67.3863H160.981L161.001 67.4061ZM157.945 54.7268C162.469 54.7268 164.87 51.7108 164.87 48.2384C164.87 44.766 162.469 43.6349 160.009 43.6349H153.203L150.762 54.7268H157.945Z"
                                    fill="white"/>
                                <path
                                    d="M184.455 41.1934C190.824 41.1934 196.003 45.1817 196.003 52.3646C196.003 59.5476 189.594 67.8615 181.736 67.8615C173.879 67.8615 170.148 63.8732 170.148 56.6903C170.148 49.5073 176.558 41.1934 184.435 41.1934H184.455ZM184.256 43.2173C178.006 43.2173 172.549 48.8128 172.549 56.6307C172.549 64.4487 176.756 65.8575 181.855 65.8575C186.955 65.8575 193.602 60.2619 193.602 52.444C193.602 44.6261 189.356 43.2173 184.256 43.2173Z"
                                    fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_78_256">
                                    <rect width="209" height="67.861" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div style={{height: '2rem'}}></div>
                        <p className={'landingBlockDescription'}>Elevate your business space with stunning art
                            and innovative messaging using our texture-accurate digital canvases, capturing the
                            attention of customers and staff alike.</p>
                        <button
                            className="homeMainButton black-border-hover-white"
                            style={{width: "100%"}}
                            onClick={_ => window.location.href = '/business/home'}
                        >
                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                EXPLORE VIEUNITE PRO
                            </p>
                        </button>
                    </div>
                    {/*<AnimatePresence mode="wait">*/}
                    {/*    {brightness2 === 0.7 && (*/}
                    {/*        <motion.div*/}
                    {/*            key={brightness2}*/}
                    {/*            initial={{x: -40, opacity: 0}}*/}
                    {/*            animate={{x: 0, opacity: 1}}*/}
                    {/*            exit={{x: 40, opacity: 0}}*/}
                    {/*            transition={{duration: 0.5}}*/}
                    {/*            style={{*/}
                    {/*                width: '50%',*/}
                    {/*                height: '100%',*/}
                    {/*                position: 'relative',*/}
                    {/*                display: "flex",*/}
                    {/*                flexDirection: 'column',*/}
                    {/*                alignItems: 'flex-start',*/}
                    {/*                justifyContent: 'flex-end',*/}
                    {/*                padding: '9vw'*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="209" height="68" viewBox="0 0 209 68"*/}
                    {/*                 fill="none">*/}
                    {/*                <g clip-path="url(#clip0_78_256)">*/}
                    {/*                    <path*/}
                    {/*                        d="M13.4531 35.7765L0 0.953125H8.4727L18.0963 27.7007L27.7 0.953125H36.1925L22.7195 35.7765H13.4531Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M38.5547 3.84943C38.5547 1.64692 40.3405 0 42.5232 0C44.7058 0 46.4916 1.72629 46.4916 3.84943C46.4916 5.97256 44.7058 7.81791 42.5232 7.81791C40.3405 7.81791 38.5547 6.09162 38.5547 3.84943ZM39.2095 10.5562H45.8567V35.7163H39.2095V10.5562Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M50.7773 23.1353C50.7773 15.8333 56.1745 9.90039 63.8336 9.90039C71.4928 9.90039 76.4733 15.4761 76.4733 23.7901V25.2783H57.7222C58.1984 28.4729 60.6787 31.0921 64.9647 31.0921C69.2506 31.0921 70.0642 30.1396 71.6714 28.5324L74.5882 32.7985C72.0286 35.1201 68.0601 36.2908 64.2107 36.2908C56.6705 36.3503 50.7972 31.1913 50.7972 23.1154M63.8932 15.1785C59.8056 15.1785 58.0198 18.254 57.7222 20.7542H70.1237C69.9451 18.3136 68.2188 15.1785 63.8932 15.1785Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M98.2396 35.7763V32.5817C96.5133 34.5461 93.4378 36.3716 89.3502 36.3716C85.2627 36.3716 81.2148 33.296 81.2148 28.4743V10.5566H87.862V25.8551C87.862 29.3474 89.6479 30.4784 92.4853 30.4784C95.3228 30.4784 97.0491 29.0498 98.2396 27.6211V10.6559H104.887V35.816L98.2396 35.7565V35.7763Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M128.497 35.7759V20.5369C128.497 17.0447 126.712 15.8541 123.874 15.8541C121.037 15.8541 119.251 17.3423 118.12 18.8106V35.7759H111.473V10.5562H118.12V13.8104C119.787 11.846 122.803 9.96094 126.95 9.96094C131.097 9.96094 135.145 13.096 135.145 18.0368V35.8354L128.497 35.7759Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M140.961 3.84943C140.961 1.64692 142.747 0 144.929 0C147.112 0 148.898 1.72629 148.898 3.84943C148.898 5.97256 147.112 7.81791 144.929 7.81791C142.747 7.81791 140.961 6.09162 140.961 3.84943ZM141.616 10.5562H148.263V35.7163H141.616V10.5562Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M156.081 29.4859V16.3105H151.934V10.5563H156.081V3.73047H162.728V10.6158H167.827V16.3701H162.728V27.7001C162.728 29.2478 163.561 30.4979 165.049 30.4979C166.538 30.4979 167.014 30.1407 167.371 29.724L168.8 34.7045C167.847 35.5974 166.002 36.3712 163.283 36.3712C158.66 36.3712 156.1 33.9306 156.1 29.4859"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M170.805 23.1353C170.805 15.8333 176.202 9.90039 183.861 9.90039C191.52 9.90039 196.501 15.4761 196.501 23.7901V25.2783H177.75C178.226 28.4729 180.706 31.0921 184.992 31.0921C189.278 31.0921 190.091 30.1396 191.699 28.5324L194.616 32.7985C192.056 35.1201 188.087 36.2908 184.238 36.2908C176.638 36.3503 170.825 31.1913 170.825 23.1154M183.921 15.1785C179.833 15.1785 178.047 18.254 177.75 20.7542H190.151C189.972 18.3136 188.246 15.1785 183.921 15.1785Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M200.467 32.106C200.467 29.7844 202.431 27.8398 204.733 27.8398C207.035 27.8398 208.999 29.8042 208.999 32.106C208.999 34.4077 207.035 36.3721 204.733 36.3721C202.431 36.3721 200.467 34.4077 200.467 32.106Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M123.676 67.4061L129.351 41.6309H138.399C142.447 41.6309 145.165 44.6469 145.165 48.0796C145.165 51.5124 142.467 56.6912 135.621 56.6912H128.279L125.878 67.3863H123.676V67.4061ZM135.879 54.6872C140.482 54.6872 142.804 51.6314 142.804 48.2384C142.804 44.8453 140.8 43.6349 138.042 43.6349H131.117L128.676 54.6872H135.879Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M161.001 67.4061L156.14 56.7508H150.306L147.945 67.4061H145.742L151.417 41.6309H160.267C163.739 41.6309 167.251 43.9921 167.251 48.1193C167.251 52.2465 163.779 56.473 158.441 56.6516L163.501 67.3863H160.981L161.001 67.4061ZM157.945 54.7268C162.469 54.7268 164.87 51.7108 164.87 48.2384C164.87 44.766 162.469 43.6349 160.009 43.6349H153.203L150.762 54.7268H157.945Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                    <path*/}
                    {/*                        d="M184.455 41.1934C190.824 41.1934 196.003 45.1817 196.003 52.3646C196.003 59.5476 189.594 67.8615 181.736 67.8615C173.879 67.8615 170.148 63.8732 170.148 56.6903C170.148 49.5073 176.558 41.1934 184.435 41.1934H184.455ZM184.256 43.2173C178.006 43.2173 172.549 48.8128 172.549 56.6307C172.549 64.4487 176.756 65.8575 181.855 65.8575C186.955 65.8575 193.602 60.2619 193.602 52.444C193.602 44.6261 189.356 43.2173 184.256 43.2173Z"*/}
                    {/*                        fill="white"/>*/}
                    {/*                </g>*/}
                    {/*                <defs>*/}
                    {/*                    <clipPath id="clip0_78_256">*/}
                    {/*                        <rect width="209" height="67.861" fill="white"/>*/}
                    {/*                    </clipPath>*/}
                    {/*                </defs>*/}
                    {/*            </svg>*/}
                    {/*            <div style={{height: '2rem'}}></div>*/}
                    {/*            <p className={'landingBlockDescription'}>Elevate your business space with stunning art*/}
                    {/*                and innovative messaging using our texture-accurate digital canvases, capturing the*/}
                    {/*                attention of customers and staff alike.</p>*/}
                    {/*            <button*/}
                    {/*                className="homeMainButton black-border-hover-white"*/}
                    {/*                style={{width: "100%"}}*/}
                    {/*                onClick={_ => window.location.href = '/business/home'}*/}
                    {/*            >*/}
                    {/*                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>*/}
                    {/*                    EXPLORE VIEUNITE PRO*/}
                    {/*                </p>*/}
                    {/*            </button>*/}
                    {/*        </motion.div>*/}
                    {/*    )}*/}
                    {/*</AnimatePresence>*/}
                </div>
            </div>
        </div>

        <div ref={homeSectionRef}
             style={{
                 display: 'flex',
                 flexDirection: 'row',
                 width: '100vw',
                 height: '120vh',
                 position: 'relative',
                 pointerEvents: 'none'
             }}>
            {/*<img className={'landingVideoImg'}*/}
            {/*     style={{transition: 'filter 2s ease', filter: `brightness(${brightness2})`}} src={background2}/>*/}
            {/*<AnimatePresence mode="wait">*/}
            {/*    {brightness2 === 0.7 && (*/}
            {/*        <motion.div*/}
            {/*            key={brightness2}*/}
            {/*            initial={{x: -40, opacity: 0}}*/}
            {/*            animate={{x: 0, opacity: 1}}*/}
            {/*            exit={{x: 40, opacity: 0}}*/}
            {/*            transition={{duration: 0.5}}*/}
            {/*            style={{*/}
            {/*                width: '50%',*/}
            {/*                height: '100%',*/}
            {/*                position: 'relative',*/}
            {/*                display: "flex",*/}
            {/*                flexDirection: 'column',*/}
            {/*                alignItems: 'flex-start',*/}
            {/*                justifyContent: 'flex-end',*/}
            {/*                padding: '9vw'*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="209" height="68" viewBox="0 0 209 68"*/}
            {/*                 fill="none">*/}
            {/*                <g clip-path="url(#clip0_78_256)">*/}
            {/*                    <path*/}
            {/*                        d="M13.4531 35.7765L0 0.953125H8.4727L18.0963 27.7007L27.7 0.953125H36.1925L22.7195 35.7765H13.4531Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M38.5547 3.84943C38.5547 1.64692 40.3405 0 42.5232 0C44.7058 0 46.4916 1.72629 46.4916 3.84943C46.4916 5.97256 44.7058 7.81791 42.5232 7.81791C40.3405 7.81791 38.5547 6.09162 38.5547 3.84943ZM39.2095 10.5562H45.8567V35.7163H39.2095V10.5562Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M50.7773 23.1353C50.7773 15.8333 56.1745 9.90039 63.8336 9.90039C71.4928 9.90039 76.4733 15.4761 76.4733 23.7901V25.2783H57.7222C58.1984 28.4729 60.6787 31.0921 64.9647 31.0921C69.2506 31.0921 70.0642 30.1396 71.6714 28.5324L74.5882 32.7985C72.0286 35.1201 68.0601 36.2908 64.2107 36.2908C56.6705 36.3503 50.7972 31.1913 50.7972 23.1154M63.8932 15.1785C59.8056 15.1785 58.0198 18.254 57.7222 20.7542H70.1237C69.9451 18.3136 68.2188 15.1785 63.8932 15.1785Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M98.2396 35.7763V32.5817C96.5133 34.5461 93.4378 36.3716 89.3502 36.3716C85.2627 36.3716 81.2148 33.296 81.2148 28.4743V10.5566H87.862V25.8551C87.862 29.3474 89.6479 30.4784 92.4853 30.4784C95.3228 30.4784 97.0491 29.0498 98.2396 27.6211V10.6559H104.887V35.816L98.2396 35.7565V35.7763Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M128.497 35.7759V20.5369C128.497 17.0447 126.712 15.8541 123.874 15.8541C121.037 15.8541 119.251 17.3423 118.12 18.8106V35.7759H111.473V10.5562H118.12V13.8104C119.787 11.846 122.803 9.96094 126.95 9.96094C131.097 9.96094 135.145 13.096 135.145 18.0368V35.8354L128.497 35.7759Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M140.961 3.84943C140.961 1.64692 142.747 0 144.929 0C147.112 0 148.898 1.72629 148.898 3.84943C148.898 5.97256 147.112 7.81791 144.929 7.81791C142.747 7.81791 140.961 6.09162 140.961 3.84943ZM141.616 10.5562H148.263V35.7163H141.616V10.5562Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M156.081 29.4859V16.3105H151.934V10.5563H156.081V3.73047H162.728V10.6158H167.827V16.3701H162.728V27.7001C162.728 29.2478 163.561 30.4979 165.049 30.4979C166.538 30.4979 167.014 30.1407 167.371 29.724L168.8 34.7045C167.847 35.5974 166.002 36.3712 163.283 36.3712C158.66 36.3712 156.1 33.9306 156.1 29.4859"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M170.805 23.1353C170.805 15.8333 176.202 9.90039 183.861 9.90039C191.52 9.90039 196.501 15.4761 196.501 23.7901V25.2783H177.75C178.226 28.4729 180.706 31.0921 184.992 31.0921C189.278 31.0921 190.091 30.1396 191.699 28.5324L194.616 32.7985C192.056 35.1201 188.087 36.2908 184.238 36.2908C176.638 36.3503 170.825 31.1913 170.825 23.1154M183.921 15.1785C179.833 15.1785 178.047 18.254 177.75 20.7542H190.151C189.972 18.3136 188.246 15.1785 183.921 15.1785Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M200.467 32.106C200.467 29.7844 202.431 27.8398 204.733 27.8398C207.035 27.8398 208.999 29.8042 208.999 32.106C208.999 34.4077 207.035 36.3721 204.733 36.3721C202.431 36.3721 200.467 34.4077 200.467 32.106Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M123.676 67.4061L129.351 41.6309H138.399C142.447 41.6309 145.165 44.6469 145.165 48.0796C145.165 51.5124 142.467 56.6912 135.621 56.6912H128.279L125.878 67.3863H123.676V67.4061ZM135.879 54.6872C140.482 54.6872 142.804 51.6314 142.804 48.2384C142.804 44.8453 140.8 43.6349 138.042 43.6349H131.117L128.676 54.6872H135.879Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M161.001 67.4061L156.14 56.7508H150.306L147.945 67.4061H145.742L151.417 41.6309H160.267C163.739 41.6309 167.251 43.9921 167.251 48.1193C167.251 52.2465 163.779 56.473 158.441 56.6516L163.501 67.3863H160.981L161.001 67.4061ZM157.945 54.7268C162.469 54.7268 164.87 51.7108 164.87 48.2384C164.87 44.766 162.469 43.6349 160.009 43.6349H153.203L150.762 54.7268H157.945Z"*/}
            {/*                        fill="white"/>*/}
            {/*                    <path*/}
            {/*                        d="M184.455 41.1934C190.824 41.1934 196.003 45.1817 196.003 52.3646C196.003 59.5476 189.594 67.8615 181.736 67.8615C173.879 67.8615 170.148 63.8732 170.148 56.6903C170.148 49.5073 176.558 41.1934 184.435 41.1934H184.455ZM184.256 43.2173C178.006 43.2173 172.549 48.8128 172.549 56.6307C172.549 64.4487 176.756 65.8575 181.855 65.8575C186.955 65.8575 193.602 60.2619 193.602 52.444C193.602 44.6261 189.356 43.2173 184.256 43.2173Z"*/}
            {/*                        fill="white"/>*/}
            {/*                </g>*/}
            {/*                <defs>*/}
            {/*                    <clipPath id="clip0_78_256">*/}
            {/*                        <rect width="209" height="67.861" fill="white"/>*/}
            {/*                    </clipPath>*/}
            {/*                </defs>*/}
            {/*            </svg>*/}
            {/*            <div style={{height: '2rem'}}></div>*/}
            {/*            <p className={'landingBlockDescription'}>Elevate your business space with stunning art and*/}
            {/*                innovative messaging using our texture-accurate digital canvases, capturing the attention of*/}
            {/*                customers and staff alike.</p>*/}
            {/*            <button*/}
            {/*                className="homeMainButton black-border-hover-white"*/}
            {/*                style={{width: "100%"}}*/}
            {/*                onClick={_ => window.location.href = '/business/home'}*/}
            {/*            >*/}
            {/*                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>*/}
            {/*                    EXPLORE VIEUNITE PRO*/}
            {/*                </p>*/}
            {/*            </button>*/}
            {/*        </motion.div>*/}
            {/*    )}*/}
            {/*</AnimatePresence>*/}
        </div>

        <div style={{
            width: '100%',
            backgroundColor: '#F0F0F0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            padding: '6rem 8vw'
        }}>
            <p className={'landingCreationsTitle'}>Unlock Timeless Artworks and Creations</p>
            <p className={'landingCreationsBody'}>Through strategic partnerships with prestigious art galleries
                worldwide, Vieunite brings you exclusive access to curated collections of iconic artworks.
                Each partnership represents our commitment to delivering unparalleled cultural richness and aesthetic
                value to your space. Explore our gallery collaborations and unlock a world of artistic excellence.</p>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {demoArts.map((obj, index) =>
                    // <img className={'landingCreationsImg'} src={obj} alt={index}/>
                    <div style={{
                        backgroundImage: `url(${obj.img})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center'
                    }} className={'landingCreationsImg'}>
                        <div className={'landingDemoCard'}>
                            <div className={'spacer'}></div>
                            <p className={'artistTitle'}>{obj.artist}</p>
                            <p className={'artistBody'}>{obj.artwork}</p>
                            <div className={'spacer'}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <div style={{
            backgroundColor: '#DFDFDF',
            position: 'relative',
            display: "flex",
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{width: '40vw', minWidth: '400px', display: 'flex', flexDirection: 'column', margin: '4vw'}}>
                <p className={'landingInnovatingTitle'}>Vieunite: Innovating for Tomorrow</p>
                <p className={'landingInnovatingBody'}>At Vieunite, we blend art and technology through academic
                    research, R&D initiatives, and our AI-powered
                    platform, Vieutopia, featuring both timeless masterpieces and emerging talents. Stay updated on our
                    innovations and community contributions via our News Page and social media.</p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.location.href = '/research'}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        LEARN MORE
                    </p>
                </button>
            </div>
            <img src={innovating_cover} alt={"innovating cover"}
                 style={{width: '25vw', minWidth: '300px', height: 'auto', margin: '4vw'}}/>
        </div>

        <div className={'landingSection'}>
            <p className={'landingSectionTitle'}>Vieunite in Action</p>
        </div>

        <div
            className={`landingMarqueeContainer ${action1Pause ? 'paused1' : ''}`}
            onMouseEnter={() => {
                setAction1Pause(true)
            }}
            onMouseLeave={() => {
                setAction1Pause(false)
            }}
        >
            <div className={'landingMarqueeContent1'}>
                {action1_list.map((obj, index) =>
                    // <img className={'landingMarqueeItem'} src={obj} alt={index}/>
                    <div className={'landingMarqueeItem'}>
                        <div
                            style={{
                                backgroundImage: `url(${obj})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                width: '450px',
                                height: '320px'
                            }}>
                            <div
                                className={'landingActionCard'}>
                                <p className={'cardTitle'}>{action1_title_list[index]}</p>
                                <p className={'cardBody'}>{action1_body_list[index]}</p>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    onClick={_ => window.open(action1_link_list[index])}
                                    style={{width: "100%"}}>
                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                        {action1_button_list[index]}
                                    </p>
                                </button>
                            </div>
                        </div>
                        {/*<img src={obj} alt={index}/>*/}
                    </div>
                )}
            </div>
        </div>

        <div style={{height: '20px', background: '#F0F0F0', position: 'relative'}}></div>

        <div
            className={`landingMarqueeContainer ${action2Pause ? 'paused2' : ''}`}
            onMouseEnter={() => {
                setAction2Pause(true)
            }}
            onMouseLeave={() => {
                setAction2Pause(false)
            }}
        >
            <div className={'landingMarqueeContent2'}>
                {action2_list.map((obj, index) =>
                    <div className={'landingMarqueeItem'}>
                        <div
                            style={{
                                backgroundImage: `url(${obj})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                width: '450px',
                                height: '320px'
                            }}>
                            <div
                                className={'landingActionCard'}>
                                <p className={'cardTitle'}>{action2_title_list[index]}</p>
                                <p className={'cardBody'}>{action2_body_list[index]}</p>
                                <button
                                    className="homeMainButton black-border-hover-white"
                                    onClick={_ => window.open(action2_link_list[index])}
                                    style={{width: "100%"}}>
                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                        {action2_button_list[index]}
                                    </p>
                                </button>
                            </div>
                        </div>
                        {/*<img src={obj} alt={index}/>*/}
                    </div>
                )}
            </div>
        </div>

        <div style={{height: '8rem'}} className={'landingSection'}></div>

        <div className={'landingSection'}>
            <p className={'landingPartnershipTitle'}>IN PARTNERSHIP WITH</p>
            <div className={'landingPartnershipContainer'}>
                <img onClick={() => {
                    window.open("https://www.ukri.org/councils/innovate-uk/")
                }}
                     alt={'logo'} className={'logo-img landingLogo'} src={UKTILogo}></img>

                <img onClick={() => {
                    window.open("https://royalsociety.org/")
                }}
                     alt={'logo'} className={'logo-img landingLogo'} src={RoyalLogo}></img>

                <img onClick={() => {
                    window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")
                }}
                     alt={'logo'} className={'logo-img landingLogo'} src={NTULogo}></img>

                <img onClick={() => {
                    window.open("https://www.ox.ac.uk/")
                }}
                     alt={'logo'} className={'logo-img landingLogo'} src={oxfordLogo}
                     style={{objectFit: 'contain'}}></img>
            </div>
        </div>

        <div style={{height: '8rem'}} className={'landingSection'}></div>

        <div className={'landingSection landingPartnershipContainer'}>
            <p className={'landingGetStartedTitle'}>Embark on Your Vieunite Journey</p>
            <p className={'landingGetStartedBody'}>At Vieunite, we’re
                driven by a vision to make inspirational art accessible to everyone. Whether at home or
                in business, our goal is to transform the world through the fusion of art and technology. Join us on
                this remarkable journey and help us shape a brighter, more beautiful future. Start your Vieunite journey
                today by reaching out to us.</p>
            <div style={{height: '1rem', width: '100%'}} className={'landingSection'}></div>
            <button
                className="homeMainButton black-border-hover-white"
                onClick={_ => window.location.href = '/contact'}
                style={{width: "40vw"}}>
                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                    CONTACT US
                </p>
            </button>
        </div>

        <div style={{height: '8rem'}} className={'landingSection'}></div>

        {/*<div style={{height: '200vh', width: '100vw', backgroundColor: 'green'}}></div>*/}
    </div>)
}

export default Landing;