import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const Page6 = () => {
    // State to track the selected radio button
    const [relevanceOption, setRelevanceOption] = useState(null);
    const [anticipationOption, setAnticipationOption] = useState(null);
    const [recommendationOption, setRecommendationOption] = useState(null);
    const [suggestionText, setSuggestionText] = useState('');
    const [optionsString, setOptionsString] = useState('');
    const navigate = useNavigate();

    const bio = ['Marine', 'Natural Landscape', 'Animals', 'Plants & Trees', 'Presence of Water', 'Seascape', 'Seasonal & Natural Phenomenon', 'Still Life'];
    const getSelectedBioFeatures = (selectedCriteria) => {
        const selectedBio = selectedCriteria
            .map((bit, index) => bit === 1 ? bio[index] : null)
            .filter(item => item !== null); // Remove null values
        return selectedBio.join(', '); // Convert the list to a comma-separated string
    };

    const fetchLocalStorageData = () => {
        const savedFormData = JSON.parse(localStorage.getItem('formData'));

        const selectedCriteria = JSON.parse(localStorage.getItem('selectedCriteria'));
        const selectedEmotions = JSON.parse(localStorage.getItem('emotions'));
        const selectedEmotions2 = JSON.parse(localStorage.getItem('emotions2'));

        const imageData = JSON.parse(localStorage.getItem('imageData'))?.files_list;
        const imageUrls = imageData ? imageData.map(item => item.url) : [];

        if (selectedCriteria) {
            const options = getSelectedBioFeatures(selectedCriteria);
            setOptionsString(options);
        }

        return { savedFormData, selectedCriteria, imageUrls, selectedEmotions, selectedEmotions2 };
    };

    // Handle submit button click
    const handleSubmit = async () => {
        // Fetch the data from local storage
        const { savedFormData, selectedCriteria, imageUrls, selectedEmotions, selectedEmotions2 } = fetchLocalStorageData();

        // Check if all necessary data exists
        if (!savedFormData || !selectedCriteria || !imageUrls) {
            message.error("Missing required data from local storage!");
            return;
        }
        const dataToSend = {
            consent: savedFormData.consent,
            participant_code: savedFormData.participant_code,
            laptop: savedFormData.laptop === 'Yes' ? 'Yes' : 'No',
            age: parseInt(savedFormData.age, 10) || 0,
            ethnicity: savedFormData.ethnicity,
            Gender: savedFormData.Gender,
            residence: savedFormData.residence,
            spaces: savedFormData.spaces,
            spaces2: savedFormData.spaces2,
            fluency: savedFormData.fluency,
            selectedCriteria: selectedCriteria,
            files_list: imageUrls,
            Relevance: relevanceOption,
            Anticipation: anticipationOption,
            Recommendation: recommendationOption,
            Suggestions: suggestionText,
            emotions: selectedEmotions,
            emotions2: selectedEmotions2
        };
        console.log('Data to send:', dataToSend);
        try {
            const response = await axios.post('https://api.vieunite.com/research/p/Reco/savedata', dataToSend);
            message.success('Data saved successfully!');
        } catch (error) {
            message.error('Failed to save data.');
            console.error("API Error: ", error);
        }
        navigate('/Submit');
    };


    // Handle radio button change
    const handleRelevanceChange = (event) => setRelevanceOption(event.target.value);
    const handleAnticipationChange = (event) => setAnticipationOption(event.target.value);
    const handleRecommendationChange = (event) => setRecommendationOption(event.target.value);

    useEffect(() => {
        // Fetch local storage data when the component mounts
        fetchLocalStorageData();
    }, []);
    const isFormComplete = relevanceOption && anticipationOption && recommendationOption;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '140vh'
        }}>
            <div style={{ fontSize: '16px', textAlign: 'center', marginBottom: '1vw', fontWeight: "bolder" }}>
                <p>You have selected the Biophilic Features: {optionsString}, please select the appropriate options for the below questions.</p>
            </div>

            {/* Section 1: Relevance */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                width: '30vw',
                marginBottom: '1vw',

            }}>
                <p style={{fontWeight: "bolder"}}>How relevant were our recommendations? <span style={{ color: 'red' }}>*</span></p>
                {['Completely Irrelevant', 'Irrelevant', 'Somewhat Relevant', 'Relevant', 'Extremely Relevant'].map(option => (
                    <div key={option} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5vw', width: '50%' }}>
                        <label htmlFor={option}>{option}</label>
                        <input
                            type="radio"
                            id={option}
                            name="relevance"
                            value={option}
                            onChange={handleRelevanceChange}
                        />
                    </div>
                ))}
            </div>

            {/* Section 2: Anticipation */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                width: '30vw',
                marginBottom: '1vw',

            }}>
                <p style={{fontWeight: "bolder"}}>Were the images displayed as per your anticipation of the selected Biophilic features? <span style={{ color: 'red' }}>*</span></p>
                {['Strongly Disagree', 'Disagree', 'Somewhat Agree', 'Agree', 'Strongly Agree'].map(option => (
                    <div key={option} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5vw', width: '50%' }}>
                        <label htmlFor={option}>{option}</label>
                        <input
                            type="radio"
                            id={option}
                            name="anticipation"
                            value={option}
                            onChange={handleAnticipationChange}
                        />
                    </div>
                ))}
            </div>

            {/* Section 3: Recommendation */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
                width: '30vw',
                marginBottom: '1vw',

            }}>
                <p style={{fontWeight: "bolder"}}>How likely are you to use the system or recommend it to friends/family? <span style={{ color: 'red' }}>*</span></p>
                {['Totally Unlikely','Unlikely', 'Somewhat Likely', 'Likely','Highly Likely'].map(option => (
                    <div key={option} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5vw', width: '50%' }}>
                        <label htmlFor={option}>{option}</label>
                        <input
                            type="radio"
                            id={option}
                            name="recommendation"
                            value={option}
                            onChange={handleRecommendationChange}
                        />
                    </div>
                ))}
            </div>

            {/* Section 4: Suggestions */}
            <div style={{ width: '30vw', marginBottom: '1vw', fontWeight: "bolder" }}>
                <p>Do you have any suggestions for us to improve our recommendation system?</p>
                <textarea
                    style={{ width: '100%', height: '5vw',  }}
                    value={suggestionText}
                    onChange={(e) => setSuggestionText(e.target.value)}
                    placeholder="Enter your suggestions here..."
                />
            </div>

            {/* Submit button */}
            <div style={{ marginTop: '0.5vw', textAlign: 'center' }}>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!isFormComplete} // Disable if any required option is not selected
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default Page6;
