import React from 'react';
import "./HelpOverview.css"
import {Col, Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import image1 from "./VieuniteApp/image001.png";
import image2 from "./VieuniteApp/image002.png";
import image3 from "./VieuniteApp/image003.png";
import image4 from "./VieuniteApp/image004.png";
import image5 from "./VieuniteApp/image005.png";
import image6 from "./VieuniteApp/image006.png";
import image7 from "./VieuniteApp/image007.png";
import image8 from "./VieuniteApp/image008.png";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

function HelpVieuniteApp() {
    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="help-center-top-section">
                    <div className="help-center-text-content">
                        <h2 className={'help-center-subtitle'}>Vieunite Home
                            Support</h2>
                        <h3 className={'help-center-title'}>How Can We
                            Help?</h3>
                    </div>
                </div>
                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingTop: '2rem',
                    paddingLeft: '8vw'
                }}>
                    <a href={'/home/support'} style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Vieunite App
                </p>
                <Row justify={"center"}
                     style={{
                         backgroundColor: '#C2D9D6',
                         margin: "2rem 8vw 4rem 8vw",
                         borderRadius: "15px",
                         overflow: "hidden",
                         height: "auto"
                     }}
                >
                    <Col span={10} lg={10} xs={24}>
                        <div style={{
                            marginLeft: '10%', height: '100%', display: 'flex', flexDirection: 'column'
                        }}>
                            <div className='spacer' style={{minHeight: '40px'}}></div>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center",
                            }}>
                                {/*<img src={YouTubeLogo}/>*/}
                                <h2 className="title-app"
                                    style={{
                                        fontSize: '2rem', textAlign: 'left', lineHeight: 1, margin: 0 // margin: "0 1rem"
                                    }}
                                >
                                    Connecting You to a World of Art
                                </h2>
                            </div>

                            <p className="text-content-app"
                               style={{width: '100%', paddingRight: '2rem'}}>
                                Welcome to the Vieunite App! Explore and connect with a vast universe of art,
                                discovering new inspirations and creative visions. While Vieunite offers intuitive
                                control over your artistic journey, its true focus is on enriching your experience and
                                connection to remarkable artworks.
                            </p>
                            <div className='spacer'></div>
                        </div>

                    </Col>

                    <Col span={14} lg={{span: 14}} xs={24}>
                        <div className="iframe-container">
                            <iframe width="640" height="auto"
                                    src="https://www.youtube.com/embed/kq1upWekTPk?si=CPaeHy1HpAtse-dx&amp;controls=0"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                    style={{margin: "4rem 2rem", aspectRatio: '16/10'}}></iframe>
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"}
                     style={{
                         // backgroundColor: '#f4f4f4',
                     }}>
                    <Col span={20} lg={20} md={20}>
                        <Collapse expandIconPosition={"end"}
                                  bordered={false}
                                  style={{
                                      backgroundColor: '#f0f0f0', color: '#f4f4f4', border: "none"
                                  }}
                                  expandIcon={(panelProps) => {
                                      if (panelProps.isActive) {
                                          return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      } else {
                                          return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      }
                                  }}
                        >
                            <Panel header="How to upload digital art to your Textura digital canvas"
                                   className={'help-center-collapse-copy'}
                                   key="7">
                                <div className={'iframe-container'}>
                                    <iframe width="560" height="auto"
                                            style={{aspectRatio: '16/10'}}
                                            src="https://www.youtube.com/embed/aQmpSRyC-58?si=V6JSQ9wZ8DiOEDj2"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </Panel>
                            <Panel header="I'm having trouble logging into my account, what can I do?"
                                   className={'help-center-collapse-copy'}
                                   key="1">
                                <p>If you’re having trouble logging into your account, please follow these steps:</p>
                                <ol>
                                    <li>
                                        <span
                                            className={'help-answer-bold-list'}>Use the "Forgotten Password" Feature:</span> When
                                        you open
                                        the <span>Vieunite</span> application, look for the "Forgotten Password" option.
                                        This will allow you to initiate a password reset process. Follow the on-screen
                                        instructions to reset your password.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Check Your Email:</span> If you don’t
                                        remember your username, try entering
                                        the last email address you used to sign up for the account. The system will send
                                        a password reset link to that email address if it’s associated with
                                        your <span>Vieunite</span> account.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Receive and Use the Password Reset Link:</span> Check
                                        your email inbox
                                        (and possibly your spam/junk folder) for a password reset email. Click on the
                                        link provided in the email to reset your password and regain access to your
                                        account.
                                    </li>
                                </ol>
                                <p>If you encounter any <span>issues</span> during this process or do not receive the
                                    reset email, please contact our support team at <a
                                        href="mailto:Support@vieunite.com">support@vieunite.com </a> for further
                                    assistance.</p>
                            </Panel>
                            <Panel header="How do I remove videos and images from my account?"
                                   className={'help-center-collapse-copy'}
                                   key="2">
                                <p>To delete items, follow these steps:</p>
                                <ol>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Go to the Library.</span> <br/>
                                        <img src={image1}
                                             className={'help-answer-portrait-image-limit'} alt={'image1'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Navigate to Personal Library.</span>
                                        <br/>
                                        <img src={image2}
                                             className={'help-answer-portrait-image-limit'} alt={'image2'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}> Select the items you want to delete and then proceed to delete them. </span>
                                        <br/>
                                        <img src={image3}
                                             className={'help-answer-portrait-image-limit'} alt={'image3'}></img><br/>&nbsp;
                                    </li>
                                </ol>
                                <p>If you encounter any <span>issues</span> during this process or do not receive the
                                    reset email, please contact our support team at <a
                                        href="mailto:Support@vieunite.com">support@vieunite.com</a> for further
                                    assistance.</p>
                            </Panel>
                            <Panel header="How do I remove albums from my account?"
                                   className={'help-center-collapse-copy'}
                                   key="3">
                                <p>To delete albums, follow these steps:</p>
                                <ol>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Go to the Library.</span> <br/>
                                        <img src={image1}
                                             className={'help-answer-portrait-image-limit'} alt={'image1'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Navigate to the album you wish to remove.</span>
                                        <br/>
                                        <img src={image4}
                                             className={'help-answer-portrait-image-limit'} alt={'image4'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Select the album you want to delete and then proceed to delete it.</span>
                                        <br/>
                                        <img src={image5}
                                             className={'help-answer-portrait-image-limit'} alt={'image5'}></img><br/>&nbsp;
                                    </li>
                                </ol>
                            </Panel>
                            <Panel header="Why is my Vieunite app not working?"
                                   className={'help-center-collapse-copy'}
                                   key="4">
                                <p>If your Vieunite app isn't working, please follow these steps to resolve the
                                    issue:</p>
                                <ol>
                                    <li>
                                        <span
                                            className={'help-answer-bold-list'}> Update Your Mobile Operating System:</span>
                                        Ensure that your phone is running the latest
                                        version of iOS or Android. Go to your phone's settings and check for any
                                        available
                                        updates. Installing the latest operating system update can resolve compatibility
                                        issues.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Update the Vieunite App:</span> Make
                                        sure you have the latest version of the Vieunite app.
                                        Open the Google Play Store (for Android devices) or the Apple App Store (for iOS
                                        devices)
                                        and check if an update is available for the Vieunite app. If there is, download
                                        and install the update.
                                    </li>
                                </ol>
                                <p>Updating both your mobile operating system and the app should address most issues. If
                                    the problem continues after these updates, there might be other factors involved,
                                    and
                                    additional troubleshooting may be necessary. In that case, please contact <a
                                        href="mailto:Support@vieunite.com">support@vieunite.com </a>
                                    for further assistance.</p>
                            </Panel>
                            <Panel header="How do I delete my account?"
                                   className={'help-center-collapse-copy'}
                                   key="5">
                                <p>To delete your account, please follow these steps:</p>
                                <ol>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Access Profile Settings: </span> Open
                                        the Vieunite application and go to your profile settings. This is typically
                                        found
                                        in the menu or under your account name.
                                        <br/>
                                        <img src={image6}
                                             className={'help-answer-portrait-image-limit'} alt={'image6'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Go to Account Settings:</span> Within
                                        your profile settings, locate and select the "Account" section.
                                        <br/>
                                        <img src={image7}
                                             className={'help-answer-portrait-image-limit'} alt={'image7'}></img><br/>&nbsp;
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Delete Your Account:</span>In the
                                        "Account"
                                        section, you should find the option to delete your account. Follow the prompts
                                        to
                                        confirm the deletion.
                                        <br/>
                                        <img src={image8}
                                             className={'help-answer-portrait-image-limit'} alt={'image8'}></img><br/>&nbsp;
                                    </li>
                                </ol>
                                <p>Please note that deleting your account is usually irreversible and will result in the
                                    loss of all data associated with it. If you encounter any issues or need further
                                    assistance,
                                    feel free to contact our support team at <a
                                        href="mailto:Support@vieunite.com">support@vieunite.com</a>. </p>
                            </Panel>
                            <Panel header="What are the recommended supported media formats for my Textura?"
                                   className={'help-center-collapse-copy'}
                                   key="6">
                                <p>Before uploading any files to the Vieunite Pro software, please ensure that they meet
                                    the following requirements:</p>
                                <p className={'help-answer-bold-list'}>Image:</p>
                                <ul>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Container: </span> JPEG
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Compression: </span> Baseline
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Colour Mode: </span> RGB
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Width: </span> 1920 (1080 for
                                        portrait)
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Height: </span> 1080 (1920 for
                                        portrait)
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>DPI: </span> 300
                                    </li>
                                </ul>
                                <p className={'help-answer-bold-list'}>Video:</p>
                                <ul>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Container: </span> MP4, AVI, MPG
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Codec: </span> MPEG-1, MPEG-2, MPEG-4,
                                        XVID*, H.264
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Width: </span> 1920 (1080 for
                                        portrait)
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Height: </span> 1080 (1920 for
                                        portrait)
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Audio: </span> AAC or MP3
                                    </li>
                                </ul>
                                <p>For help with resizing content, please see the best quality content for your Textura
                                    digital canvas below: </p>
                                <div className={'iframe-container'} style={{justifyContent: 'flex-start'}}>
                                    <iframe width="640" height="auto"
                                            style={{aspectRatio: '16/10'}}
                                            src="https://www.youtube.com/embed/X3dItvqKT2s?si=RfM8MHAziNAwkvjB"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                    ></iframe>
                                </div>
                                {/*<a href="https://www.youtube.com/watch?v=X3dItvqKT2s">here</a>.*/}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>

                <Row justify={"center"}
                     style={{
                         backgroundColor: 'black',
                         margin: "0 8vw 4vw 8vw",
                         borderRadius: "15px",
                     }}
                >
                <div style={{padding: "3rem", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className="title-app m-text"
                            style={{
                                color: 'white',
                                display: "flex",
                                justifyContent: "center",
                                margin: '0 1rem',
                            }}>
                            Request Support
                        </h2>
                        <p className="text-content-app m-text"
                           style={{
                               color: 'white',
                               textAlign: "center",
                               paddingLeft: '8rem',
                               paddingRight: '8rem',
                               // paddingTop: "1rem",
                               // paddingBottom: "1rem",
                               // margin: '0 1rem',
                           }}>
                            If you have a question that is not answered here, or you are having any issues with using
                            your
                            Textura digital canvas, please get in touch with us today via our support form
                        </p>
                        <button
                            className="homeMainButton product-access-support-button"
                            onClick={_ => window.location.href = "/home/support/form"}
                            style={{width: '30vw', marginTop: '20px'}}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                SUPPORT FORM
                            </a>
                        </button>
                    </div>
                </Row>
            </Content>
        </Layout>
    </div>)
}

export default HelpVieuniteApp;