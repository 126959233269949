import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import 'antd/dist/antd.min.css';
import './App.css';
import HomePage from "./pages/Home/HomePage";
import TestCaroucel from "./AutoplayCarousel/AutoplayCarousel"
// import HomePageTest from "./pages/Home/HomePageJun";
import ArtPage from "./pages/Art/ArtPage";
import ArtPageMobile from "./pages/Art/ArtPageMobile";
import BrochurePage from "./pages/Art/Brochure";
import HomePageTest from "./pages/Home/HomePageJack";
import HomeVieuniteApp from "./pages/Home/VieuniteApp";
import ProductDetail from "./pages/Textura/ProductDetail";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import BasicLayout from "./BasicLayout";//root
import Artists from "./pages/Artists/Artists";
import TermsAndConditions from "./pages/Terms/TermsAndConditions";
import EndUserAgreement from "./pages/Terms/EndUserAgreement";
import AppDownload from "./pages/AppDownload/AppDownload";
import Profile from "./pages/Profile/Profile";

import AutoScrollToTop from "./components/AutoScrollToTop";
import Login from "./apps/Login/Login";
import ArtistPortalHome from "./apps/ArtistPortal/ArtistPortalHome/ArtistPortalHome";
import Contact from "./pages/Contact";
import HelpMain from "./pages/Help/HelpMain";
import HelpVieuniteApp from "./pages/Help/HelpVieuniteApp";
import HelpTextura from "./pages/Help/HelpTextura";
import HelpOnlineStore from "./pages/Help/HelpOnlineStore";
import HelpRequestSupport from "./pages/Help/HelpRequestSupport";
import PortalLayout from "./apps/ArtistPortal/Layout/PortalLayout";
import ArtList from "./apps/ArtistPortal/ArtList";
import ProfileEditor from "./apps/ArtistPortal/ProfileEditor";
import BalanceBoard from "./apps/ArtistPortal/BalanceSystem/BalanceBoard";
import TransactionHistory from "./apps/ArtistPortal/BalanceSystem/TransactionHistory";
import WithdrawHistory from "./apps/ArtistPortal/BalanceSystem/WithdrawHistory";
import ForgotPassword from "./apps/Login/ForgotPassword";
import Apply from "./apps/Apply";
import Finish from "./apps/Apply/finish";
import ApplyLogin from "./apps/Login/ApplyLogin";
import {Checkbox, Modal} from "antd";
import bg_img from './img/background.png'
import SaleModal from "./components/SaleModal";
import ProHome from "./pro/ProHome";
import ProSubPage from "./pro/Subscription";
import ProLayout from "./pro/ProLayout";
import CuratedCollections from "./pro/CuratedCollections";
import Survey from "./pages/Survey/Survey";
import Page2 from "./pages/Survey/Page2";
import Page3 from "./pages/Survey/Page3";
import Page4 from "./pages/Survey/Page4";
import Page5 from "./pages/Survey/Page5";
import Page6 from "./pages/Survey/Page6";
import ImagePage from './pages/Survey/ImagePage';
import Submit from "./pages/Survey/Submit";
import TexturaPro from "./pro/TexturaPro";
import Shopping from "./pro/Shopping";
import ProLogin from "./pro/ProLogin";
import ProSignIn from "./pro/ProSignIn";
import ProSignUp from "./pro/ProSignUp";
import ProForgotPassword from "./pro/ProForgotPassword";
import Software from "./pro/Software";
import ProPortalHome from "./portal/ProPortalHome";
import DashBoard from "./portal/DashBoard";
import MyLibrary from "./portal/MyLibrary";
import MyAccountHome from "./portal/MyAccount";
import MyAccount from "./portal/MyAccount/ExtraServices";
import DiscoveryCollections from "./portal/ProDiscovery/DiscoveryCollections";
import AuthorDetails from "./portal/ProDiscovery/AuthorDetails";
import GalleryDetails from "./portal/ProDiscovery/GalleryDetails";
import AlbumDetails from "./portal/ProDiscovery/AlbumDetails";
import DiscoveryArtworkSeeAll from "./portal/ProDiscovery/DiscoveryArtworkSeeAll";
import DiscoveryGalleriesSeeAll from "./portal/ProDiscovery/DiscoveryGalleriesSeeAll";
import DiscoveryArtistSeeAll from "./portal/ProDiscovery/DiscoveryArtistSeeAll"
import ImgDisplay from "./portal/MyLibrary/ImgDisplay";
import MyOrdersPro from "./portal/MyAccount/MyOrders";
import AccountSettings from "./portal/MyAccount/AccountSettings";
import Albums from "./portal/MyLibrary/Playlists";
import ShoppingCart from "./portal/ShoppingCart";
import ChangeAccountDetails from "./portal/MyAccount/AccountSettings/ChangeSettings";
import ChangePasswordPro from "./portal/MyAccount/AccountSettings/ChangePassword";
import ProportalSupport from "./portal/ProSupport";
import ProportalQA from "./portal/ProSupport/QA";
import ProContact from "./portal/ProSupport/ProContact";
import ProTutorial from "./portal/ProSupport/ProTutorial";
import UpgradeSubscription from "./portal/MyAccount/ExtraServices/UpgradeSubscription";
import Hardware from "./portal/DashBoard/components/Hardware";
import PortalOrderDetails from "./portal/MyAccount/MyOrders/OrderDetails";
import RefundsProportal from "./portal/MyAccount/MyOrders/MyRefunds";
import ProNewPassword from "./pro/ProNewPassword";
import ProportalGroup from "./portal/ProportalGroup";
import SingleAlbumDetails from "./portal/MyLibrary/SingleAlbumDetails";
import MyAlbums from "./portal/MyLibrary/MyAlbums";
import UpgradeSummary from "./portal/MyAccount/ExtraServices/UpgradeSummary";
import PaymentAgreementTermsAndConditions from "./pro/Payment/PaymentAgreement";
import ProSignUpVerification from "./pro/ProSignUpVerification";
import ProResetPassword from "./pro/ProResetPassword";
import ProCongratulations from "./pro/ProCongratulations";
import ProPasswordSaved from "./pro/ProPasswordSaved";
import UpgradeSubscriptionPremium from "./portal/MyAccount/ExtraServices/UpgradeSubscriptionPremium";
import ProPrivacyPolicy from "./pro/ProPrivatePolicy";
import ProTermsAndConditions from "./pro/ProTermsAndConditions";
import CheckOut from "./pro/Success";
import Explore from "./pro/Explore";
import Success from "./pro/Success";
import ArtistsTermsAndConditions from "./pro/ArtistsTermsAndConditions";
import ProductDetailsNew from "./pages/Textura/ProductDetailsNew";
import LandingPage from "./LandingPage";
import LandingMobilePage from "./LandingPageMobile";
import AutoplayCarousel from "./AutoplayCarousel/AutoplayCarousel";
import AssignDevices from "./portal/MyAccount/AccountSettings/AssignDevices";
import Biophilia from "./pages/Research/Biophilia";
import Compad from "./pages/Research/compad";
import Research from "./pages/Research";
import FeaturedArtist from "./pages/FeaturedArtist";
import NewArtist from "./pages/NewArtist";
import ProductDetailsNewJack from "./pages/Textura/ProductDetailsNewJack";
import NewContact from "./pages/NewContact";
import HomePageJack from "./pages/Home/HomePageJack";
import Museum from "./pages/Museum";

const NAMES = ['jonathanarmour', 'keyang',
    'lucynelson', 'jovincent',
    'jenettecoldrick', 'barbara',
    'christianredermayer', 'dorisluming',
    'linwangwang',
    'richardbattye', 'guillegiagante',
    'fazarwibisono', 'caitlinflood',
    'rorywatson', 'anthonywebb', 'angeliquenagovskaya',
    'ethanplatt', 'matthewstickland', 'alexanderrhys'
];
const MuseumNames = [
    'NationalGalleriesScotland', 'EstorickCollection', 'RBSA','ArtInstituteofChicago','NationalGalleryofArt','Rijksmuseum'
]

const App = () => {
    useEffect(() => {
        // only mobile webView will trigger this function and send the token here
        window.setToken = (tokenFromWebView) => {
            localStorage.setItem("token", tokenFromWebView);
            return tokenFromWebView
        };
        return () => {
            delete window.setToken;
        }
    }, []);

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const isMobile = useMediaQuery({query: '(max-width: 933px)'});

    return (
        <div>
            <BrowserRouter>
                <AutoScrollToTop>
                    <Routes>
                        <Route path='contact' element={<NewContact/>}/>
                        <Route path='test-caroucel' element={<AutoplayCarousel/>}></Route>
                        <Route path="/" element={<BasicLayout/>}>
                            <Route index={true} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>
                            <Route path="home" element={<HomePageJack/>}/>

                            <Route path="research" element={<Research/>}/>
                            <Route path="research/biophilia" element={<Biophilia/>}/>
                            <Route path="research/compad" element={<Compad/>}/>

                            <Route path="textura" element={<ProductDetailsNewJack/>}/>
                            <Route path="product" element={<ProductDetailsNewJack/>}/>
                            <Route path="digital-canvas" element={<ProductDetailsNewJack/>}/>
                            <Route path="home/digital-canvas" element={<ProductDetailsNewJack/>}/>
                            <Route path={'digital-frame'} element={<ProductDetailsNewJack/>}/>
                            {/*<Route path="art/:page" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>*/}
                            <Route path="art" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>
                            <Route path="brochure" element={<BrochurePage/>}/>
                            <Route path="home/vieunite-app" element={<HomeVieuniteApp/>}/>
                            <Route path="home/support" element={<HelpMain/>}/>
                            <Route path="home/support/form" element={<HelpRequestSupport/>}/>
                            <Route path="home/support/vieunite-app" element={<HelpVieuniteApp/>}/>
                            <Route path="home/support/textura" element={<HelpTextura/>}/>
                            <Route path="home/support/online-store" element={<HelpOnlineStore/>}/>

                            <Route path='artists' element={<Artists/>}/>
                            <Route path="terms-and-conditions" element={<TermsAndConditions/>}/>
                            <Route path="end-user-agreement" element={<EndUserAgreement/>}/>
                            <Route path={'ArtistsTermsAndConditions'} element={<ArtistsTermsAndConditions/>}/>
                            <Route path="app.html" element={<AppDownload/>}/>
                            {NAMES.map(
                                (n) => <Route key={n} path={'artist/' + n} element={<NewArtist name={n}/>}/>
                            )}
                            {MuseumNames.map(
                                (n) => <Route key={n} path={'museum/' + n} element={<Museum name={n}/>}/>
                            )}
                            {NAMES.map(
                                (n) => <Route key={n} path={'featuredartist/' + n}
                                              element={<FeaturedArtist name={n}/>}/>
                            )}
                            <Route path="Survey" element={<Survey/>}/>
                            <Route path="Page2" element={<Page2/>}/>
                            <Route path="Page3" element={<Page3/>}/>
                            <Route path="Page4" element={<Page4/>}/>
                            <Route path="ImagePage" element={<ImagePage/>}/>
                            <Route path="Page5" element={<Page5/>}/>
                            <Route path="Page6" element={<Page6/>}/>
                            <Route path="Submit" element={<Submit/>}/>
                        </Route>
                        <Route path="/artistportal/login" element={<Login/>}/>
                        <Route path="/artistportal/mapply" element={<ApplyLogin/>}/>
                        <Route path="/artistportal/forgotpassword" element={<ForgotPassword/>}/>
                        <Route path='/artistportal/apply' element={<Apply/>}/>
                        <Route path='/artistportal/finish' element={<Finish/>}/>
                        <Route path="/artistportal" element={<PortalLayout/>}>
                            <Route index element={<ArtistPortalHome/>}/>
                            {/*Homepage's url needs to receive params: token, id and isArtist*/}
                            <Route path="home/:userInfo" element={<ArtistPortalHome/>}/>
                            <Route path="home" element={<ArtistPortalHome/>}/>
                            <Route path='artlist' element={<ArtList/>}/>
                            <Route path='profile' element={<ProfileEditor/>}/>
                            <Route path='balance' element={<BalanceBoard/>}/>
                            <Route path='transactionhistory' element={<TransactionHistory/>}/>
                            <Route path='withdrawhistory' element={<WithdrawHistory/>}/>
                        </Route>
                        <Route path="/proportal" element={<ProPortalHome/>}/>
                        <Route path="/proportal/dashboard" element={<DashBoard/>}/>
                        <Route path="/proportal/ProportalGroup" element={<ProportalGroup/>}/>
                        <Route path={"/proportal/mylibrary"} element={<MyLibrary/>}/>
                        <Route path="/proportal/MyAccountHome" element={<MyAccountHome/>}/>
                        <Route path="/proportal/MyAccount" element={<MyAccount/>}/>
                        <Route path="/proportal/UpgradeSubscription" element={<UpgradeSubscription/>}/>
                        <Route path="/proportal/UpgradeSubscriptionPremium" element={<UpgradeSubscriptionPremium/>}/>
                        <Route path="/proportal/UpgradeSummary" element={<UpgradeSummary/>}/>
                        <Route path="/proportal/MyOrder" element={<MyOrdersPro/>}/>
                        <Route path="/proportal/RefundsProportal" element={<RefundsProportal/>}/>
                        <Route path="/proportal/ChangeAccountDetails" element={<ChangeAccountDetails/>}/>
                        <Route path="/proportal/AssignDevices" element={<AssignDevices/>}/>
                        <Route path="/proportal/ChangePasswordPro" element={<ChangePasswordPro/>}/>
                        <Route path="/proportal/AccountSettings" element={<AccountSettings/>}/>
                        <Route path={"/proportal/discover-art"} element={<DiscoveryCollections/>}/>
                        <Route path={"/proportal/discover-art/author-details"} element={<AuthorDetails/>}/>
                        <Route path={"/proportal/discover-art/gallery-details"} element={<GalleryDetails/>}/>
                        <Route path={"/proportal/discover-art/album-details"} element={<AlbumDetails/>}/>
                        <Route path={"/proportal/discover-art/artworks-seeall"}
                               element={<DiscoveryArtworkSeeAll/>}/>
                        <Route path={"/proportal/discover-art/galleries-seeall"}
                               element={<DiscoveryGalleriesSeeAll/>}/>
                        <Route path={"/proportal/discover-art/artists-seeall"} element={<DiscoveryArtistSeeAll/>}/>
                        <Route path={"/proportal/images"} element={<ImgDisplay/>}/>
                        <Route path={"/proportal/albums"} element={<Albums/>}/>
                        <Route path={"proportal/my-albums"} element={<MyAlbums/>}/>
                        <Route path={"/proportal/album-details"} element={<SingleAlbumDetails/>}/>
                        <Route path={"/proportal/shopping-cart"} element={<ShoppingCart/>}/>
                        <Route path={"/proportal/ProportalSupport"} element={<ProportalSupport/>}/>
                        <Route path={"/proportal/ProportalQA"} element={<ProportalQA/>}/>
                        <Route path={"/proportal/ProContact"} element={<ProContact/>}/>
                        <Route path={"/proportal/ProTutorial"} element={<ProTutorial/>}/>
                        <Route path={"/proportal/textura"} element={<Hardware/>}/>
                        <Route path="/proportal/PortalOrderDetails" element={<PortalOrderDetails/>}/>
                        <Route path={'/proportal/ProPaymentAgreement'}
                               element={<PaymentAgreementTermsAndConditions/>}/>

                        <Route path={'/business/explore'} element={<Explore/>}/>
                        <Route path={'/business'} element={<ProLayout/>}>
                            <Route index element={<ProHome/>}/>
                            <Route path={'home'} element={<ProHome/>}/>
                            <Route path={'/business/success'} element={<Success/>}/>
                            <Route path={'ProLogin'} element={<ProLogin/>}/>
                            <Route path={'ProSignIn'} element={<ProSignIn/>}/>
                            <Route path={'ProSignUp'} element={<ProSignUp/>}/>
                            <Route path={'ProSignUpVerification'} element={<ProSignUpVerification/>}/>
                            <Route path={'ProResetPassword'} element={<ProResetPassword/>}/>
                            <Route path={'ProCongratulations'} element={<ProCongratulations/>}/>
                            <Route path={'ProForgotPassword'} element={<ProForgotPassword/>}/>
                            <Route path={'ProPasswordSaved'} element={<ProPasswordSaved/>}/>
                            <Route path={'ProNewPassword'} element={<ProNewPassword/>}/>
                            <Route path={'subscription'} element={<ProSubPage/>}/>
                            <Route path={'curated-collections'} element={<CuratedCollections/>}/>
                            <Route path={'textura-pro'} element={<TexturaPro/>}/>
                            <Route path={'shopping'} element={<Shopping/>}/>
                            <Route path={'software'} element={<Software/>}/>
                            <Route path={'ProPrivacyPolicy'} element={<ProPrivacyPolicy/>}/>
                            <Route path={'ProTermsAndConditions'} element={<ProTermsAndConditions/>}/>

                        </Route>
                    </Routes>
                </AutoScrollToTop>
            </BrowserRouter>
        </div>);
}

export default App;