import React, { useState, useEffect } from 'react';
import { Table, Radio, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Page5 = () => {
    const navigate = useNavigate();

    const columns = [
        {
            title: ' ',
            dataIndex: 'criteria',
            width: '1vw',
        },
        {
            title: 'Very Slightly or not at all',
            dataIndex: 'slightly',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'slightly')}
                    value={emotionState[record.criteria] === 'slightly' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '1vw',
        },
        {
            title: 'A little',
            dataIndex: 'a little',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'a little')}
                    value={emotionState[record.criteria] === 'a little' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '1vw',
        },
        {
            title: 'Moderately',
            dataIndex: 'moderately',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'moderately')}
                    value={emotionState[record.criteria] === 'moderately' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '1vw',
        },
        {
            title: 'Quite a bit',
            dataIndex: 'quite a bit',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'quite a bit')}
                    value={emotionState[record.criteria] === 'quite a bit' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '1vw',
        },
        {
            title: 'Extremely',
            dataIndex: 'extremely',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRadioChange(e, record.criteria, 'extremely')}
                    value={emotionState[record.criteria] === 'extremely' ? 1 : null}
                >
                    <Radio value={1}></Radio>
                </Radio.Group>
            ),
            width: '1vw',
        },
    ];

    const data = [
        { key: '1', criteria: 'Relaxed,Calm' },
        { key: '2', criteria: 'Proud,Grand' },
        { key: '3', criteria: 'Nourished, Fulfilled' },
        { key: '4', criteria: 'Attentive, Concentrating' },
        { key: '5', criteria: 'Sad, Downhearted' },
        { key: '6', criteria: 'Afraid, Frightened' },
        { key: '7', criteria: 'Upset, Distressed' },
        { key: '8', criteria: 'Inspired, Amazed' },
        { key: '9', criteria: 'Energised, Excited' },
        { key: '10', criteria: 'Happy, Cheerful' },
        { key: '11', criteria: 'Determined, Confident' },
        { key: '12', criteria: 'Safe, Cosy' },
        { key: '13', criteria: 'Ashamed, Guilty' },
        { key: '14', criteria: 'Shy, Bashful' },
        { key: '15', criteria: 'Hostile, Angry' },
    ];

    // Flatten the state so each criteria is a key with its selected value
    const initialEmotionState = {
        'Relaxed,Calm': null,
        'Proud,Grand': null,
        'Nourished, Fulfilled': null,
        'Attentive, Concentrating': null,
        'Sad, Downhearted': null,
        'Afraid, Frightened': null,
        'Upset, Distressed': null,
        'Inspired, Amazed': null,
        'Energised, Excited': null,
        'Happy, Cheerful': null,
        'Determined, Confident': null,
        'Safe, Cosy': null,
        'Ashamed, Guilty': null,
        'Shy, Bashful': null,
        'Hostile, Angry': null,
    };

    const [emotionState, setEmotionState] = useState(initialEmotionState);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleRadioChange = (e, criteria, emotion) => {
        setEmotionState((prevEmotionState) => ({
            ...prevEmotionState,
            [criteria]: emotion, // Directly update the criteria with the emotion
        }));
    };

    useEffect(() => {
        // Check if all options are selected (none are null)
        const allSelected = Object.values(emotionState).every(value => value !== null);

        // Enable the button if all options are selected
        setIsButtonDisabled(!allSelected);
    }, [emotionState]);

    const handleSubmit = () => {
        const choicesArray = data.map(item => {
            const emotionValue = emotionState[item.criteria];
            // Map the selected emotions to numeric choices
            return emotionValue === 'slightly' ? 0 :
                emotionValue === 'a little' ? 1 :
                    emotionValue === 'moderately' ? 2 :
                        emotionValue === 'quite a bit' ? 3 :
                            emotionValue === 'extremely' ? 4 : null;
        });

        // Save choicesArray to local storage
        localStorage.setItem('emotions2', JSON.stringify(choicesArray));
        console.log('Emotion choices:', choicesArray);

        // Navigate to the next page
        navigate('/page6');
    };

    return (
        <div>
            <div style={{
                fontSize: '15px',
                marginTop: '5vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: "bold"
            }}>
                <p style={{fontWeight: "bolder"}}>Now that you have viewed Biophilic images recommended by our system,
                    for each emotion below, please indicate to what extent you feel this way right now.</p>
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    style={{
                        marginTop: '1vw',
                        fontWeight: "bold",
                        width: '70vw',  // Adjust the width as needed
                    }}
                    tableLayout={"fixed"}
                />
            </div>
            <div style={{
                marginTop: '5vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: "bold"
            }}>
                <Button type="primary" onClick={handleSubmit} disabled={isButtonDisabled}>Next</Button>
            </div>
        </div>
    );
};

export default Page5;
