import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Progress } from 'antd';

const ImagePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const { imageUrls } = state || {};
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    const lastIndex = imageUrls ? imageUrls.length - 1 : 0; // Ensure lastIndex is computed outside of useEffect

    // Image cycling logic
    useEffect(() => {
        if (imageUrls && imageUrls.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => {
                    // Stop incrementing when the last image is displayed
                    if (prevIndex < lastIndex) {
                        return prevIndex + 1;
                    } else {
                        clearInterval(intervalId); // Stop the interval when reaching the last image
                        return prevIndex; // Stop at the last image
                    }
                });

                // Use currentIndex instead of prevIndex to update progress
                setProgress((currentIndex + 1) * (100 / (lastIndex + 1)));
            }, 5000);

            // Cleanup: Clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, [imageUrls, lastIndex, currentIndex]); // Add currentIndex to the dependency array

    // Navigation to the next page after the last image is displayed
    useEffect(() => {
        if (currentIndex === lastIndex) {
            // Delay navigation after the last image is displayed
            const timeoutId = setTimeout(() => {
                const imageData = {
                    files_list: imageUrls.map((url) => ({ url })),
                };

                localStorage.setItem('imageData', JSON.stringify(imageData));
                navigate('/Page5');
            }, 5000); // Wait 5 seconds after the last image

            return () => clearTimeout(timeoutId); // Cleanup the timeout if component unmounts early
        }
    }, [currentIndex, lastIndex, imageUrls, navigate]);

    // Check if imageUrls is not available or is an empty array
    if (!imageUrls || imageUrls.length === 0) {
        return <div>No images available</div>;
    }

    // Set a fixed height for the images and center them
    const containerStyle = {
        height: '80vh', // 100% of the viewport height
        marginLeft: '12vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imageStyle = {
        height: '50vh', // Set your desired height
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: "100vw"}}>
            {/* Centered Heading */}
            <h1 style={{fontSize: '15px', textAlign: 'center', marginTop: '5vw'}}>
                In the next series, we are going to display a series of Biophilic artworks based on the features you
                selected.
            </h1>

            {/* Container for Image and Progress Bar */}
            <div style={containerStyle}>
                {/* Image */}
                <img src={imageUrls[currentIndex]} alt={`Image ${currentIndex + 1}`} style={imageStyle}/>

                {/* Progress Bar placed below the image */}

            </div>
            <div style={{width: '100%', textAlign: 'center', fontSize: '14px'}}>
                {currentIndex + 1}/{imageUrls.length}
            </div>
        </div>
    );
};

export default ImagePage;
