import React, {useEffect} from 'react';
import "./HelpOverview.css"
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import square1 from "./img/help1square.png"
import square2 from "./img/help2square.png"
import square3 from "./img/help3square.png"

function HelpMain(props) {
    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="help-center-top-section">
                    <div className="help-center-text-content">
                        <h2 className={'help-center-subtitle'}>Vieunite Home
                            Support</h2>
                        <h3 className={'help-center-title'}>How Can We
                            Help?</h3>
                    </div>
                </div>

                <div className="help-center-options-section">
                    <div className={'help-center-option-card-box'} onClick={()=>{window.location.href='/home/support/vieunite-app'}}>
                        <img src={square1} className={'help-center-option-card'}/>
                    </div>
                    <div className={'help-center-option-card-box'} onClick={()=>{window.location.href='/home/support/textura'}}>
                        <img src={square2} className={'help-center-option-card'}/>
                    </div>
                    <div className={'help-center-option-card-box'} onClick={()=>{window.location.href='/home/support/online-store'}}>
                        <img src={square3} className={'help-center-option-card'}/>
                    </div>
                </div>

                <div className="help-center-support-bottom-section">
                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Request Support</p>
                    <p className="homeSupportBody">If you have a question that is not answered here, or you are having any issues with using your Textura digital canvas, please get in touch with us today via our support form
                    </p>
                    <button className="homeSupportButton" onClick={()=>{window.location.href='/home/support/form'}}>
                        <p className={'homeSupportButtonLnk homeOverV'} style={{margin: 0}} >
                            SUPPORT FORM
                        </p>
                    </button>
                </div>

            </Content>
        </Layout>
    </div>)
}

export default HelpMain;