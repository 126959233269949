import React from 'react';
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import vieutopia_logo from './img/vieutopia_logo.png'
import r1 from "./img/research1.png"
import r2 from "./img/research2.png"
import r3 from "./img/research3.png"
import r4 from "./img/research4.png"
import r5 from "./img/research5.png"
import r6 from "./img/research6.png"
import r7 from "./img/research7.png"
import r8 from "./img/research8.png"
import r9 from "./img/research9.png"
import s3_0 from "./img/s3-0.png"
import s3_1 from "./img/s3-1.png"
import s3_2 from "./img/s3-2.png"
import {useMediaQuery} from "react-responsive";

const Research = (props) => {
    const examples = [r1, r2, r3, r4, r5, r6, r7, r8, r9];
    const example_loop = [...examples, ...examples];
    const isMobile = useMediaQuery({query: '(max-width: 1000px)'});
    return (
        <div style={{backgroundColor: '#F0F0F0'}}>
            <div style={{height: '4rem'}}></div>

            <div className="researchHighlightContainer">
                <p className={'researchS1Title'}>Pioneering Art Research</p>
                <p className={'researchS1Body'}>At Vieunite, we're not just about digital art frames and online
                    galleries;
                    we actively engage in academic and R&D initiatives that drive innovation at the intersection
                    of art
                    and technology.
                    Our expansive library showcases timeless masterpieces and emerging talents.</p>
            </div>


            <div style={{backgroundColor: '#FFF', paddingTop: '3rem', paddingBottom: '3rem'}}>
                <div className='Research-AI-ArtContainer'>
                    <div className='Research-AI-Art'>
                        <div style={{
                            width: '100%',
                            borderRadius: '15px',
                            overflow: 'hidden',
                        }}>
                            <img src={s3_0} className="research-artist-avatar"  onClick={_ => window.open("https://vieutopia.com")}></img>
                        </div>
                    </div>
                    <div className='Research-AI-ArtContainer-Text shine'>
                        <p className={'researchS2Title'}>AI Art Generating</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            margin: '0.8rem 0'
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                 viewBox="0 0 13 13"
                                 fill="none">
                                <circle cx="6.5" cy="6.5" r="6.5" fill="#38B000"/>
                            </svg>
                            <p className={'researchS2Ongoing'}>LIVE</p>
                        </div>
                        <p className={'researchS2Body'}>At Vieunite, we're not just about digital art frames
                            and
                            online
                            galleries;
                            we actively engage in academic and R&D initiatives that drive innovation at the
                            intersection
                            of
                            art and technology.
                            Our expansive library showcases timeless masterpieces and emerging tale</p>
                        <div style={{height: '0.8rem'}}></div>

                        <button
                            className="homeMainButton black-border-hover-white researchbutton"
                            onClick={_ => window.open("https://vieutopia.com")}
                        >
                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                GO TO WEBSITE
                            </p>
                        </button>
                    </div>
                </div>
            </div>


                <div className={'researchS3'}>
                    <div className='researchS3-Container'>
                        <div style={{width: '100%', borderRadius: '15px', overflow: 'hidden'}}>
                            <img src={s3_1}
                                 className='research-artist-avatar' onClick={_ =>  window.location.href = "research/biophilia"}></img>
                        </div>
                        <div style={{height: '0.8rem'}}></div>
                        <div className='shine'>
                            <p className={'researchS2Title'}>Biophilic Art</p>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                margin: '0.8rem 0',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
                                     fill="none">
                                    <circle cx="6.5" cy="6.5" r="6.5" fill="#E69500"/>
                                </svg>
                                <p className={'researchS2Ongoing'}>Ongoing</p>
                            </div>
                            <p className={'researchS2Body'}>This research integrates AI-powered digital displays that
                                use biophilic theories to create personalized, nature-inspired content aimed at
                                enhancing health and well-being.
                                Through Vieunite, we provide a platform for artists to showcase biophilic art, promoting
                                mental wellness while connecting art with smart building technologies.</p>
                            <div style={{height: '0.8rem'}}></div>
                            <button
                                className="homeMainButton black-border-hover-white researchbutton"
                                onClick={_ =>  window.location.href = "research/biophilia"}
                            >
                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    LEARN MORE
                                </p>
                            </button>
                        </div>
                    </div>

                    <div className='researchS4-Container'>
                        <div style={{width: '100%', borderRadius: '15px', overflow: 'hidden'}}>
                            <img src={s3_2}
                                 className='research-artist-avatar'
                                 // onClick={_ => window.open("research/compad")}></img>
                                 onClick={() => window.location.href = "research/compad"}></img>
                        </div>
                        <div style={{height: '0.8rem'}}></div>

                        <div className='shine'>
                            <p className={'researchS2Title'}>COMPaD: Commercial-Oriented Multi-modal Poster
                                Generation
                                and Design</p>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                margin: '0.8rem 0'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
                                     fill="none">
                                    <circle cx="6.5" cy="6.5" r="6.5" fill="#E69500"/>
                                </svg>
                                <p className={'researchS2Ongoing'}>Ongoing</p>
                            </div>
                            <p className={'researchS2Body'}>COMPaD revolutionises commercial poster creation by
                                integrating advanced AI technologies, offering a seamless blend of artistic style
                                and
                                commercial messaging. This platform enables efficient, interactive, and tailored
                                poster
                                design, suitable for various industries.</p>
                            <div style={{height: '0.8rem'}}></div>
                            <button
                                className="homeMainButton black-border-hover-white researchbutton"
                                // onClick={_ => window.open("research/compad")}
                                onClick={() => window.location.href = "research/compad"}
                            >
                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    LEARN MORE
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Research;
