import React from 'react';
import "./HelpOverview.css"
import {Col, Collapse, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import reset1 from "./img/reset_flow_1.png";
import reset2 from "./img/reset_flow_2.png";

const {Panel} = Collapse;

function HelpTextura() {
    return (<div>
        <Layout>
            <Content style={{backgroundColor: '#F0F0F0', fontFamily: "Proxima Nova, sans-serif"}}>
                <div className="help-center-top-section">
                    <div className="help-center-text-content">
                        <h2 className={'help-center-subtitle'}>Vieunite Home
                            Support</h2>
                        <h3 className={'help-center-title'}>How Can We
                            Help?</h3>
                    </div>
                </div>

                <p style={{
                    width: '100%',
                    color: '#6A6A6A',
                    fontSize: '1rem',
                    fontWeight: '400',
                    paddingTop: '2rem',
                    paddingLeft: '8vw'
                }}>
                    <a href={'/home/support'} style={{cursor: 'pointer', color: '#6A6A6A'}}>Support</a> / Textura
                </p>
                <Row justify={"center"}
                     style={{
                         backgroundColor: '#C7C7C7',
                         margin: "2rem 8vw 4rem 8vw",
                         borderRadius: "15px",
                         overflow: "hidden",
                         height: "auto"
                     }}
                >
                    <Col span={10} lg={10} xs={24}>
                        <div style={{
                            marginLeft: '10%', height: '100%', display: 'flex', flexDirection: 'column'
                        }}>
                            <div className='spacer' style={{minHeight: '40px'}}></div>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center",
                            }}>
                                {/*<img src={YouTubeLogo}/>*/}
                                <h2 className="title-app"
                                    style={{
                                        fontSize: '2rem', textAlign: 'left', lineHeight: 1, margin: 0// margin: "0 1rem"
                                    }}
                                >
                                    Begin Your Artistic Discovery with Textura
                                </h2>
                            </div>

                            <p className="text-content-app"
                               style={{width: '100%', paddingRight: '2rem'}}>
                                Welcome to the unboxing of Textura! Dive into an exciting journey where art and
                                technology meet. In this video, we’ll guide you through the first steps of your artistic
                                adventure, unveiling the features that will inspire your creative exploration.
                            </p>
                            <div className='spacer'></div>
                        </div>

                    </Col>

                    <Col span={14} lg={{span: 14}} xs={24}>
                        <div className="iframe-container">
                            <iframe width="640" height="auto"
                                    src="https://www.youtube.com/embed/VJ85Pn06d3A?si=8Dm8lTQFUbjxQILX"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                    style={{margin: "4rem 2rem", aspectRatio: '16/10'}}></iframe>
                            {/*<iframe width="640" height="360"*/}
                            {/*        src="https://www.youtube.com/embed/Gwf-8sevNaQ?si=J3-B4jxrV31wj3vC"*/}
                            {/*        title="YouTube video player" frameBorder="0"*/}
                            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                            {/*        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen*/}
                            {/*        style={{margin: "4rem 2rem"}}></iframe>*/}
                        </div>
                    </Col>
                </Row>
                <Row justify={"center"}
                     style={{
                         // backgroundColor: '#f4f4f4',
                     }}>
                    <Col span={20} lg={20} md={20}>
                        <Collapse expandIconPosition={"end"}
                                  bordered={false}
                                  style={{
                                      backgroundColor: '#f0f0f0', color: '#f4f4f4', border: "none"
                                  }}
                                  expandIcon={(panelProps) => {
                                      if (panelProps.isActive) {
                                          return <MinusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      } else {
                                          return <PlusOutlined style={{fontSize: '24px', fontWeight: "900"}}/>
                                      }
                                  }}
                        >
                            <Panel header="How much storage does my Textura have?"
                                   className={'help-center-collapse-copy'}
                                   key="1">
                                <p>Your Textura is equipped with an internal storage hard drive of 32GB, which is
                                    generally
                                    sufficient for most users and is unlikely to reach its full capacity.</p>
                                <p>If you do approach the storage limit, you can free up space by deleting specific
                                    content.
                                    You can store your deleted files on the cloud server, so if you need to republish
                                    any
                                    of them, you can simply re-download them from the cloud.</p>

                            </Panel>
                            <Panel header="I'm having trouble logging into my account, what can I do?"
                                   className={'help-center-collapse-copy'}
                                   key="2">
                                {/*<p>HAVE VIDEO HERE</p>*/}
                                <p>Your Textura may need to be restored to factory default settings for reasons such as
                                    changing the network that it is connected to. To do this, please follow these
                                    steps:</p>
                                <ol>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Remove frame: </span> detach the
                                        wooden
                                        frame from your Textura.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Locate the Reset Hole: </span> Find
                                        the
                                        specific area on the back of your Textura as shown in the image below.
                                        <br/>
                                        <img src={reset1} style={{width: 'auto', height: 'auto', maxWidth: '100%'}}
                                             alt={'reset step 1'}></img>
                                        {/*<br/> <span>IMAGE HERE</span><br/>&nbsp;*/}
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Insert the Reset Tool: </span> Use the
                                        reset tool included in the accessories pack of your Textura (a small object,
                                        such as a paperclip can be also be used), and insert it into the reset hole.
                                        {/*<br/> <span>IMAGE HERE</span><br/>&nbsp;*/}
                                        <br/>
                                        <img src={reset2} style={{width: 'auto', height: 'auto', maxWidth: '100%'}}
                                             alt={'reset step 2'}></img>
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Reset your Textura: </span> Keep the
                                        tool
                                        inserted for approximately 10 seconds until the device completes the reset
                                        process.
                                    </li>
                                </ol>
                                <p>Following these steps will restore your Textura to its default settings.</p>
                                <p>For a visual guide on how to reset the device, please refer to the video tutorial
                                    available <a
                                        href="https://youtu.be/CJjFFmJLk4U?si=plz69R5NxPbp8yeD">here</a>.</p>
                            </Panel>
                            <Panel header="What can I do if it seems like my Textura won’t turn on?"
                                   className={'help-center-collapse-copy'}
                                   key="3">
                                <p>If your Textura isn’t turning on as expected, please ensure that the USB Type-C cable
                                    provided is securely plugged into the power input on the mounting plate; which
                                    should
                                    be secured to your Textura. Please also check that you are using a functional wall
                                    socket and ensure that the socket is switched on.</p>
                                <p>If you are still facing any issues with your Textura, please contact us on: <a
                                    href="mailto:Support@vieunite.com">support@vieunite.com </a>.</p>

                            </Panel>
                            <Panel header="How do I change orientation of my Textura?"
                                   className={'help-center-collapse-copy'}
                                   key="4">
                                <div className={'iframe-container'} style={{justifyContent: 'flex-start'}}>
                                    <iframe width="640" height="auto"
                                            style={{aspectRatio: '16/10'}}
                                            src="https://www.youtube.com/embed/Gwf-8sevNaQ?si=J3-B4jxrV31wj3vC"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                                    ></iframe>
                                </div>
                                <p>To change the orientation of your Textura digital canvas, please take the following
                                    steps:</p>
                                <ol>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Rotate: </span> Start by rotating your
                                        Textura anti-clockwise and then carefully remove the wooden frame from it.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Remove: </span> Carefully remove your
                                        Textura from the wall.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Reposition: </span> Position your
                                        Textura
                                        in the desired orientation on the mounting plate and rotate clockwise to secure
                                        in place.
                                    </li>
                                    <li>
                                        <span className={'help-answer-bold-list'}>Reattach: </span> Secure the frame
                                        back
                                        onto your Textura, it should automatically power on if the power cable is
                                        plugged
                                        into the mounting plate.
                                    </li>
                                </ol>
                                <p>For additional guidance on changing the orientation of your Textura, please watch the
                                    video tutorial available <a
                                        href="https://youtu.be/Gwf-8sevNaQ?si=Ko4Ti0_KgjA6xRHi">here</a>.</p>
                                <p>NOTE: You’ll also need to change the orientation of your Textura via the app, for
                                    guidance on this please watch the video tutorial <a
                                        href="https://youtu.be/de5k0ZFzz-4?si=BcZj3Wu_hcME1NGZ">here</a>.</p>
                            </Panel>
                            <Panel header="How to remove back plate from Textura digital canvas?"
                                   className={'help-center-collapse-copy'}
                                   key="5">
                                <div className={'iframe-container'} style={{justifyContent: 'flex-start'}}>
                                    <iframe width="640" height="auto"
                                            style={{aspectRatio: '16/10'}}
                                            src="https://www.youtube.com/embed/7FSJ-Wj8cX0?si=rJT9UPMsdb2BMg7X"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>

                <Row justify={"center"}
                     style={{
                         backgroundColor: 'black',
                         margin: "0 8vw 4vw 8vw",
                         borderRadius: "15px",

                     }}
                >
                    <div style={{padding: "3rem", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className="title-app m-text"
                            style={{
                                color: 'white',
                                display: "flex",
                                justifyContent: "center",
                                margin: '0 0',
                            }}>
                            Request Support
                        </h2>
                        <p className="text-content-app m-text"
                           style={{
                               color: 'white',
                               textAlign: "center",
                               paddingLeft: '8rem',
                               paddingRight: '8rem',
                               // paddingTop: "1rem",
                               // paddingBottom: "1rem",
                               // margin: '0 1rem',
                           }}>
                            If you have a question that is not answered here, or you are having any issues with using
                            your
                            Textura digital canvas, please get in touch with us today via our support form
                        </p>
                        <button
                            className="homeMainButton product-access-support-button"
                            onClick={_ => window.location.href = "/home/support/form"}
                            style={{width: '30vw', marginTop: '20px'}}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                SUPPORT FORM
                            </a>
                        </button>
                    </div>
                </Row>
            </Content>
        </Layout>
    </div>)
}

export default HelpTextura;