import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Page4 = () => {
    const navigate = useNavigate();

    const data = [
        { key: '1', criteria: 'Marine', description: ': Paintings of the sea with ships or warships as the main subject.' },
        { key: '2', criteria: 'Natural Landscape', description: ': Depicting natural scenery with hills, mountains, rivers, forests, deserts, etc.' },
        { key: '3', criteria: 'Animals', description: ': Presence of any animal.' },
        { key: '4', criteria: 'Plants & Trees', description: ': Plants, trees, bushes, herbs, etc.' },
        { key: '5', criteria: 'Presence of Water', description: ': Presence of water in any form, e.g., sea, river, lake, pond, waterfall, etc.'},
        { key: '6', criteria: 'Seascape', description: ': Depicting sea beaches, cliffs, and sea view.' },
        { key: '7', criteria: 'Seasonal & Natural Phenomenon', description: ': Seasonal and weather reflections or any natural phenomena like storms, volcanic eruptions, rainbows, etc.' },
        { key: '8', criteria: 'Still Life', description: ": Still life paintings of flowers, fruits, and other small `table-top' objects." },
    ];


    const initialChoice = {
        'Marine': false,
        'Natural Landscape': false,
        'Animals': false,
        'Plants & Trees': false,
        'Presence of Water': false,
        'Seascape': false,
        'Seasonal & Natural Phenomenon': false,
        'Still Life': false,
    };

    const [choiceState, setChoiceState] = useState(initialChoice);
    const [error, setError] = useState('');
    const isAnyOptionSelected = () => {
        return Object.values(choiceState).some(value => value === true);
    };
    // Handle checkbox changes
    const handleCheckboxChange = (e, criteria) => {
        const { checked } = e.target;
        setChoiceState(prevChoiceState => ({
            ...prevChoiceState,
            [criteria]: checked, // Set true if checked, false if unchecked
        }));
    };

    const handleSubmit = () => {
        const backendEndpoint = 'https://api.vieunite.com/research/p/Reco/Reco';

        // Create an array of selected criteria
        const bitArray = Array(8).fill(0);

        // Update the bitArray based on selected criteria
        data.forEach(item => {
            if (choiceState[item.criteria]) {
                bitArray[parseInt(item.key) - 1] = 1; // Set the bit at the index of the key
            }
        });

        // Validate that at least one checkbox is selected
        if (bitArray.every(bit => bit === 0)) {
            setError('Please select at least one option.');
            return;
        }

        setError('');

        // Save the choices in localStorage (if needed)
        localStorage.setItem('selectedCriteria', JSON.stringify(bitArray));
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Choices: bitArray, // Sends the array of integers in the "Choices" field
            }),
            redirect: 'follow',
        };


        fetch(backendEndpoint, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data sent successfully:', data);

                // Extract imageUrls from the response data
                const imageUrls = data.files_list.map(item => item);


                // Use the navigate function to go to the ImagePage route and pass the imageUrls as state
                navigate('/ImagePage', {state: {imageUrls}});
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            fontSize: '15px',
        }}>
            <div style={{ textAlign: 'center', width: '50vw' }}>
                <p style={{fontWeight: "bolder"}}>
                    Our selection system will identify Biophilic images with specific features that you choose.

                </p>
                <p style={{fontWeight: "bolder"}}>
                    Please choose which features you would like incorporated into the images.
                </p>
                <p style={{marginBottom: '1vw'}}>
                    (You can choose multiple options)
                    <span style={{color: 'red'}}>*
                    </span>
                </p>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight:'600'}}>
                    {data.map(item => (
                        <div key={item.key} style={{display: 'flex', alignItems: 'center', marginBottom: '0.5vw', width: '100%'}}>

                            {/* Label and Description beside each other */}
                            <div style={{display: 'flex', flex: 1, alignItems: 'center', textAlign: 'left'}}>
                                <label style={{marginRight: '0.5vw'}}>
                                    {item.criteria}
                                </label>
                                <span style={{fontSize: '12px', color: '#555'}}>
                    {item.description}
                </span>
                            </div>

                            {/* Checkbox */}
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e, item.criteria)}
                                checked={choiceState[item.criteria]}
                                style={{marginLeft: '0.5vw'}}
                            />
                        </div>
                    ))}
                    <button
                        type="submit"
                        style={{
                            marginTop: '0.5vw',
                            padding: '0.5vw 1vw',
                            backgroundColor: isAnyOptionSelected() ? '#007BFF' : '#d3d3d3',
                            color: isAnyOptionSelected() ? 'white' : '#888',
                            cursor: isAnyOptionSelected() ? 'pointer' : 'not-allowed',
                        }}
                        disabled={!isAnyOptionSelected()} // Button is disabled when no option is selected
                    >
                        Submit
                    </button>
                </form>
                {error && <p style={{color: 'red'}}>{error}</p>}
            </div>
        </div>
    );


};

export default Page4;
